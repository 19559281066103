import React, { useEffect, useState } from 'react';
import useFetch from '../hooks/useFetch';
import endPoints from '../constants';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Bars } from 'react-loader-spinner';
import CurrencyInput from 'react-currency-input-field';
import SideDefault from '../components/SideDefault';
import { decryptData } from '../hooks/encryptionUtils';

const ACHPayOut = () => {
    const user = localStorage.getItem("skyfi");
    const userDetails = localStorage.getItem("user");
    const tk = JSON.parse(user);
    const navigate = useNavigate();
    const params = useParams();
    const [accNumber, setAccNumber] = useState("");
    const [payLoading, setPayLoading] = useState(false);
    const [achData, setAchData] = useState({
        "label": "",
        "account_number": "",
        "routing_number": "",
        // "purpose": "",
        // "bank_name": "",
        "account_type": "",
        "amount": "",
        "type": "outgoing",
        "account_id": "",
        "contact_id": "",
        "sent_from": "",
        "payment_id": "",
        "speed": "",
        "reference_id": ""
    });
    const [errors, setErrors] = useState({});

    // const { data, dataLoading } = useFetch(`${endPoints.ACH_PAYMENTS}?type=outgoing%2Cexternal%2Cincoming&account_id=${params?.acc_id?.split("_")[0]}&contact_id=${params?.contact_id?.split("_")[0]}`);
    const { data: userAcc, dataLoading: loading } = useFetch(`${endPoints.ACH_PAYMENTS_FOR_USER}`);

    useEffect(() => {
        let accNum = userAcc?.data?.map((item) => {
            return { value: item?.attributes?.account_number, label: `${item?.attributes?.label} ${item?.attributes?.account_number?.slice(-4)}` }
        });
        setAccNumber(accNum);
    }, [userAcc]);

    const isAlpha = (value) => /^[A-Za-z\s]*$/.test(value);
    const handleChange = (e) => {
        let { name, value } = e.target;
        setAchData({ ...achData, [name]: value });

        // if ((name === "label") && !isAlpha(value)) {
        //     setErrors((prevErrors) => ({
        //         ...prevErrors,
        //         [name]: "Only letters are allowed"
        //     }));
        //     return;
        // }

        if ((name === "bank_name") && !isAlpha(value)) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: "Only letters are allowed"
            }));
            return;
        }
        if ((name === "account_number") && value?.length != 16) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: "Invalid account number"
            }));
            return;
        }
        if ((name === "routing_number") && value?.length != 9) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: "Invalid routing number"
            }));
            return;
        }

        if (errors[name]) {
            setErrors((prevErrors) => ({ ...prevErrors, [name]: null }));
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        let validationErrors = {};

        if (!achData.label) validationErrors.label = "Name is required";
        if (!achData.account_number) validationErrors.account_number = "Account Number is required";
        if (!achData.routing_number) validationErrors.routing_number = "Routing Number is required";
        if (!achData.account_type) validationErrors.account_type = "Account Type is required";
        if (!achData.speed) validationErrors.speed = "Speed is required";
        // if (!achData.bank_name) validationErrors.bank_name = "Bank Name is required";
        if (!achData.amount) validationErrors.amount = "Amount is required";
        // if (!achData.purpose) validationErrors.purpose = "Description is required";

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        const datas = decryptData(userDetails);
        let resultData = {
            account_id: params?.acc_id?.split("_")[0],
            user_id: datas?.id,
            amount: achData.amount,
            speed: achData.speed,
            source: {
                account_reference_id: achData.reference_id,
            },
            destination: {
                account_number: achData.account_number,
                routing_number: achData.routing_number,
                account_type: achData.account_type,
                account_holder_name: achData.label,
                metadata: {}
            }
        }

        try {
            setPayLoading(true);


            // 1 call the api new one

            // 2. in succes of above new ap;i we need to call the endPoints.MAKE_PAY_VIA_ACH

            // 3  we need to add the same condition tit status is 404
            /*   if (error?.response?.status === 404) {
                navigate(`/ach/${params?.acc_id}/${params?.contact_id}`, { replace: true, state: resultData });
            } */
            //    curl --location 'https://api-skyfi.herokuapp.com/api/v3/check_destination_account_exists?account_number=8683828282822255' \
            const check_dest_account = await axios.get(`${process.env.REACT_APP_BASE_URL}v3/check_destination_account_exists?account_number=${achData.account_number}`, {},
                {
                    headers: {
                        'Authorization': `Bearer ${tk.access_token}`
                    }
                }
            );
            // console.log("checkout sdest accoutn", check_dest_account);

            const resp = await axios.post(`${process.env.REACT_APP_BASE_URL}${endPoints.MAKE_PAY_VIA_ACH}`, resultData,
                {
                    headers: {
                        'Authorization': `Bearer ${tk.access_token}`
                    }
                }
            );

            if (resp.status === 200) {
                toast.success("Transaction Successful ");
                navigate(`/view-contacts/${params?.acc_id}`, { replace: true });
            }
            setPayLoading(false);
        } catch (error) {
            setPayLoading(false);
            if (error?.response?.status === 404) {
                navigate(`/ach/${params?.acc_id}/${params?.contact_id}`, { replace: true, state: resultData });
            }
            if (error?.status !== 404){
                toast.error(error?.response?.data?.error);
                toast.error(error?.response?.data?.error[0]?.errorDesc);
               
            }
        }
    }

    const handleSelectAccNumber = (e) => {
        let value = e.target.value;
        const detail = userAcc?.data?.filter((item) => {
            if (item?.attributes?.account_number === value) {
                return item
            }
        });

        setAchData({
            ...achData,
            "label": detail[0]?.attributes?.label,
            // "account_number": value,
            // "routing_number": detail[0]?.attributes?.routing_number,
            // "bank_name": detail[0]?.attributes?.bank_name,
            "account_type": detail[0]?.attributes?.account_type,
            "payment_id": detail[0]?.attributes?.uuid,
            "reference_id": detail[0]?.id,
        });

        setErrors((prevErrors) => ({
            ...prevErrors,

            "label": null,
            "account_number": null,
            "routing_number": null,
            "bank_name": null,
            "account_type": null,
            "payment_id": null,

        }));

    }

    return (
        <>
            {!loading && <div className="s-layout">
                <SideDefault />
                <main className="s-layout__content">
                    <div className="main-page">
                        <div className="row justify-content-center h-100 align-items-center">
                            <div className="col-lg-8 col-md-10">
                                <div className="card plan-list account-eligibility">
                                    <div className="authincation-content">
                                        <div className="row no-gutters">
                                            <div className="col-xl-12">
                                                <div className="auth-form">
                                                    <h4 className="mb-4"><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 512 512">
                                                        <path fill="currentColor" d="M247.759 14.358L16 125.946V184h480v-58.362ZM464 152H48v-5.946l200.241-96.412L464 146.362ZM48 408h416v32H48zm-32 56h480v32H16zm40-248h32v160H56zm368 0h32v160h-32zm-96 0h32v160h-32zm-176 0h32v160h-32zm88 0h32v160h-32z" />
                                                    </svg> ACH Payment Out</h4>
                                                    <form onSubmit={handleSubmit} className="mt-4">
                                                        <div className="form-group">
                                                            <label className="mb-1 form-label">Source Account</label>
                                                            {/* <div className="input-group">
                                                                <input
                                                                    name='account_number'
                                                                    value={achData?.account_number}
                                                                    onChange={handleChange}
                                                                    type="number"
                                                                    className={`form-control`}
                                                                    aria-label="Text input with segmented dropdown button"
                                                                />
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split"
                                                                    data-bs-toggle="dropdown"
                                                                    aria-expanded="false"
                                                                >
                                                                    <span className="visually-hidden">Toggle Dropdown</span>
                                                                </button>
                                                                <ul className="dropdown-menu dropdown-menu-end">
                                                                    {accNumber?.length > 0 && accNumber?.map((item, id) => {
                                                                        return (
                                                                            <li key={id} onClick={() => handleSelectAccNumber(item?.value)}>
                                                                                <a className="dropdown-item cursor-pointer" >
                                                                                    {item?.label}
                                                                                </a>
                                                                            </li>
                                                                        )
                                                                    })}
                                                                </ul>
                                                            </div> */}
                                                            <select
                                                                name='label'
                                                                onChange={handleSelectAccNumber}
                                                                value={achData?.account_number}
                                                                className={`form-control `}
                                                                aria-label="Default select example"
                                                                placeholder="Select Account"
                                                            >
                                                                <option selected="">Select Account</option>
                                                                {accNumber?.length > 0 && accNumber?.map((item, id) => {
                                                                    return (
                                                                        <option key={id} value={item?.value}>{item?.label}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                            {errors.label && <div className="text-danger">{errors.label}</div>}
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="mb-1 form-label">Destination Account Number</label>
                                                            <input
                                                                onChange={handleChange}
                                                                value={achData?.account_number}
                                                                name='account_number'
                                                                type="text"
                                                                className={`form-control`}
                                                                placeholder="Account Number"
                                                            />
                                                            {errors.account_number && <div className="text-danger">{errors.account_number}</div>}
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="mb-1 form-label">Destination Routing Number</label>
                                                            <input
                                                                onChange={handleChange}
                                                                value={achData?.routing_number}
                                                                name='routing_number'
                                                                type="number"
                                                                className={`form-control`}
                                                                placeholder="Routing Number"
                                                            />
                                                            {errors.routing_number && <div className="text-danger">{errors.routing_number}</div>}
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="mb-1 form-label">Account Type</label>
                                                            <select
                                                                name='account_type'
                                                                onChange={handleChange}
                                                                value={achData?.account_type?.toLowerCase()}
                                                                className={`form-control `}
                                                                aria-label="Default select example"
                                                            >
                                                                <option selected="">Select Account</option>
                                                                <option value={"savings"}>Savings</option>
                                                                <option value={"checking"}>Checking</option>
                                                            </select>
                                                            {errors.account_type && <div className="text-danger">{errors.account_type}</div>}
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="mb-1 form-label">Speed</label>
                                                            <select
                                                                name='speed'
                                                                onChange={handleChange}
                                                                value={achData?.speed}
                                                                className={`form-control `}
                                                                aria-label="Default select example"
                                                            >
                                                                <option selected="">Select an option</option>
                                                                <option value={"asap"}>Asap</option>
                                                                <option value={"standard"}>Standard</option>
                                                                <option value={"same_day"}>Same Day</option>
                                                            </select>
                                                            {errors.speed && <div className="text-danger">{errors.speed}</div>}
                                                        </div>
                                                        {/* <div className="form-group">
                                                            <label className="mb-1 form-label">Bank</label>
                                                            <input
                                                                onChange={handleChange}
                                                                value={achData?.bank_name}
                                                                name='bank_name'
                                                                type="text"
                                                                className={`form-control`}
                                                                placeholder="Bank"
                                                            />
                                                            {errors.bank_name && <div className="text-danger">{errors.bank_name}</div>}
                                                        </div> */}
                                                        <div className="form-group">
                                                            <label className="mb-1 form-label">Amount</label>
                                                            <CurrencyInput
                                                                className="form-control"
                                                                name="Amount"
                                                                prefix="$"
                                                                placeholder="Amount"
                                                                defaultValue={achData?.amount}
                                                                decimalsLimit={2}
                                                                onValueChange={(value, name, values) => {
                                                                    setAchData(prev => ({ ...prev, amount: value }))
                                                                }}
                                                            />
                                                            {errors.amount && <div className="text-danger">{errors.amount}</div>}
                                                        </div>
                                                        {/* <div className="form-group">
                                                            <label className="mb-1 form-label">Description</label>
                                                            <textarea
                                                                name='purpose'
                                                                onChange={handleChange}
                                                                value={achData?.purpose}
                                                                type="text"
                                                                className={`form-control `}
                                                                placeholder="Description"
                                                                defaultValue={""}
                                                            />
                                                            {errors.purpose && <div className="text-danger">{errors.purpose}</div>}
                                                        </div> */}
                                                        <div className="text-center">
                                                            <button type="submit" className="btn">
                                                                {payLoading ? "Loading..." : "Submit"}
                                                            </button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>}
            <Bars
                height="80"
                width="80"
                color="#39AFFF"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass="bars"
                visible={loading}
            />
        </>
    );
}

export default ACHPayOut;
