
class Failure extends Error {
    constructor(message, statusCode) {
        super(message);
        this.statusCode = statusCode;
    }
}

class UnAuthorizedException extends Failure {
    constructor(){
        super("Unauthorized Access", 401)
    }
}

export {Failure, UnAuthorizedException}