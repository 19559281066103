import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { geocodeByAddress } from 'react-places-autocomplete';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import GoogleMapsComponent from '../components/GoogleMapsComponent';
import endPoints from '../constants';

const ThirdForm = ({ handlePrev, initialFormData, businessData,data }) => {
    const params = useParams();
    const navigate = useNavigate();
    const [errors, setErrors] = useState({});
    const [addressInput, setAddressInput] = useState('');
    const [contactAddress, setContactAddress] = useState({
        address1: "",
        state: '',
        city: "",
        country: "",
        zipCode: "",
    });
    const [formData, setFormData] = useState({
        doingBusinessAs: '',
        accountType: '',
        companyStructure: '',
        businessName: '',
        businessEmail: '',
        businessPhoneNumber: '',
        businessCategory: '',
        businessType: '',
        businessAddress: '',
        einOrTaxId: '',
        socialSecurityNumber: '',
        website: '',
        establishedDate: '',
        industry: '',
    });

    // Use effect to set initial form data
    useEffect(() => {
        if (data) {
            setFormData(prevData => ({
                ...prevData,
                doingBusinessAs: '',
                accountType: '',
                companyStructure: '',
                businessName: data?.legal_name,
                businessEmail: '',
                businessPhoneNumber: '',
                businessCategory: '',
                businessType: '',
                businessAddress: '',
                einOrTaxId: '',
                socialSecurityNumber: '',
                website: data?.website,
                establishedDate: data?.formation_date,
                industry: '',
            }));
        }
    }, [data]);


    const validate = () => {
        const newErrors = {};
        if (!formData.companyStructure) newErrors.companyStructure = "Company Structure is required";
        if (!formData.businessName) newErrors.businessName = "Business Name is required";
        if (!formData.businessEmail) newErrors.businessEmail = "Business Email is required";
        if (!formData.businessPhoneNumber) newErrors.businessPhoneNumber = "Business Phone Number is required";
        if (!addressInput) newErrors.businessAddress = "Business Address is required";
        if (!contactAddress.state) newErrors.state = "State is required";
        if (!contactAddress.city) newErrors.city = "City is required";
        if (!contactAddress.country) newErrors.country = "Country is required";
        if (!contactAddress.zipCode) newErrors.zipCode = "Zip Code is required";
        if (!formData.einOrTaxId) newErrors.einOrTaxId = "Employer Identification Number / TAX ID is required";
        else if (formData.einOrTaxId && formData.einOrTaxId.length != 9) newErrors.einOrTaxId = "Employer Identification Number must be 9 digits";
        if (!formData.socialSecurityNumber) newErrors.socialSecurityNumber = "Social Security Number is required";
        else if (formData.socialSecurityNumber && formData.socialSecurityNumber.length != 9) newErrors.socialSecurityNumber = "Social Security Number must be 9 digits";
        if (!formData.website) newErrors.website = "Website is required";
        if (!formData.establishedDate) newErrors.establishedDate = "Established Date is required";

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validate()) return;

        const apiData = {
            "cmp_structure": formData.companyStructure,
            "business_name": formData.businessName,
            "business_website": formData.website,
            "ein": formData.einOrTaxId,
            "dateOfIncorporation": formData.establishedDate,
            "business_address": contactAddress?.address1?.split(",")[0],
            "business_city": contactAddress.city,
            "business_state": contactAddress.state,
            "business_zip": contactAddress.zipCode,
            "business_phone": formData.businessPhoneNumber,
            "first_name": businessData.beneficiaryFirstName,
            "last_name": businessData.beneficiaryLastName,
            "phone": formData.businessPhoneNumber,
            "email": formData.businessEmail,
            "address": businessData?.contactAddress?.address1?.split(",")[0],
            "city": businessData?.contactAddress?.city,
            "state": businessData?.contactAddress?.state,
            "zip": businessData?.contactAddress?.zipCode,
            "ssn": formData.socialSecurityNumber,
            "dob": businessData?.dateOfBirth,
            "ownershipPercentage": Number(businessData.percentOwnership),
        };

        try {
            const user = localStorage.getItem("skyfi");
            const tk = JSON.parse(user);
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}${endPoints.ALL_BUSINESS}/${params?.b_id}/create_business_rollfi`, apiData,
                {
                    headers: {
                        'Authorization': `Bearer ${tk.access_token}`
                    }
                }
            );
            if (response.status === 200) {
                toast.success(response?.data?.message);
                navigate(`/${params?.b_id}/accounts`,{replace:true});
            }
        } catch (error) {
            if (error?.response?.status === 401) {
                localStorage.clear();
                navigate("/login");
            }
            toast.error(error?.response?.data?.error);
            console.log(error);
        }
    };

    const handleSelect = async (address) => {
        setAddressInput(address);
        try {
            const results = await geocodeByAddress(address);
            const addressComponents = results[0].address_components;

            const newAddress = {
                address1: address,
                state: '',
                city: '',
                country: '',
                zipCode: ''
            };

            addressComponents.forEach(component => {
                const types = component.types;
                if (types.includes('locality')) {
                    newAddress.city = component.long_name;
                }
                if (types.includes('administrative_area_level_1')) {
                    newAddress.state = component.short_name;
                }
                if (types.includes('postal_code')) {
                    newAddress.zipCode = component.long_name;
                }
                if (types.includes('country')) {
                    newAddress.country = component.long_name;
                }
            });

            setContactAddress(newAddress);
            setErrors(prevErrors => ({ ...prevErrors, businessAddress: '' }));
        } catch (error) {
            console.error('Error fetching address details:', error);
        }
    };
    const phoneRegex = /^\d{10}$/;
    const isValidEmail = (value) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
        if (name === 'businessPhoneNumber') {
            const formattedValue = value.replace(/\D/g, '');
            setFormData(prevData => ({ ...prevData, [name]: formattedValue }));
            if (formattedValue.length === 10 && phoneRegex.test(formattedValue)) {
                setErrors(prevErrors => ({ ...prevErrors, [name]: '' }));
            } else {
                setErrors(prevErrors => ({ ...prevErrors, [name]: 'Phone number must be 10 digits.' }));
                return;
            }
        }

        if  (name === "businessEmail" && !isValidEmail(value)) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                businessEmail: "Invalid email format"
            }));
            return;
        }
        // if (name === "socialSecurityNumber" && formData.socialSecurityNumber.length!=8) {
        //     setErrors((prevErrors) => ({
        //         ...prevErrors,
        //         socialSecurityNumber: "Social Security Number must be 9 digits"
        //     }));
        //     return;
        // }
        // if (name === "einOrTaxId" && formData.einOrTaxId.length!=8) {
        //     setErrors((prevErrors) => ({
        //         ...prevErrors,
        //         einOrTaxId: "Employer Identification Number must be 9 digits"
        //     }));
        //     return;
        // }
       

            
        

        if (errors[name]) {
            setErrors(prevErrors => ({ ...prevErrors, [name]: '' }));
        }
    };

    const formatPhoneNumber = (value) => {
        if (!value) return '';
        const cleaned = ('' + value).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
        if (match) {
            return [match[1], match[2], match[3]].filter(Boolean).join('-');
        }
        return value;
    };
    


    return (
        <div className="authincation content-body default-height">
            <div className="container h-100">
                <div className="row justify-content-center h-100 align-items-center">
                    <div className="col-xl-11 col-xxl-10 m-auto main-page">
                        <div className="row justify-content-center h-100 align-items-center">
                            <div className="col-lg-12 col-md-10">
                                <div className="card login-part">
                                    <div className="authincation content-body default-height">
                                        <div className="container h-100">
                                            <div className="row justify-content-center h-100 align-items-center">
                                                <div className="col-lg-12 col-md-10">
                                                    <div>
                                                        <form className="mt-4" onSubmit={handleSubmit}>
                                                            <h4>Business Details</h4>
                                                            <p>Tell us about the business</p>
                                                            {/* <div className='form-group-row'>
                                                                <div className="form-group">
                                                                    <label className="mb-1 form-label">Doing Business As</label>
                                                                    <input
                                                                        name="doingBusinessAs"
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="Doing Business As"
                                                                        value={formData.doingBusinessAs}
                                                                        onChange={(e) => setFormData({ ...formData, doingBusinessAs: e.target.value })}
                                                                    />
                                                                </div>
                                                              
                                                                <div className="form-group">
                                                                    <label className="mb-1 form-label">Account Type</label>
                                                                    <select
                                                                        name='accountType'
                                                                        className="form-control"
                                                                        
                                                                        value={formData.accountType}
                                                                        onChange={handleChange}
                                                                    >
                                                                        <option value="">Select Account Type</option>
                                                                        <option value="company">Company</option>
                                                                        <option value="individual">Individual</option>
                                                                    </select>
                                                                </div>
                                                            </div> */}
                                                            <div className='form-group-row'>
                                                                <div className="form-group">
                                                                    <label className="mb-1 form-label">Company Structure</label>
                                                                    <select
                                                                        name='companyStructure'
                                                                        className="form-control"
                                                                        value={formData.companyStructure}
                                                                        onChange={handleChange}
                                                                    >
                                                                        <option value="">Select Company Structure</option>
                                                                        <option value="sole_proprietorship">Sole Prop</option>
                                                                        <option value="LLC">LLC</option>
                                                                        <option value="Private Corporation">Private Corporation</option>
                                                                        <option value="Pubic Corporation">Pubic Corporation</option>
                                                                        <option value="Non-Profit">Non Profit</option>
                                                                        <option value="LLP">Limited Liability Partnership</option>
                                                                    </select>
                                                                    {errors.companyStructure && <small className="text-danger">{errors.companyStructure}</small>}
                                                                </div>
                                                                <div className="form-group">
                                                                    <label className="mb-1 form-label">Business Name</label>
                                                                    <input
                                                                        name='businessName'
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="Business Name"
                                                                        value={formData.businessName}
                                                                        onChange={handleChange}
                                                                    />
                                                                    {errors.businessName && <small className="text-danger">{errors.businessName}</small>}
                                                                </div>
                                                            </div>

                                                            <div className='form-group-row'>
                                                                <div className="form-group">
                                                                    <label className="mb-1 form-label">Business Email</label>
                                                                    <input
                                                                        name='businessEmail'
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="Business Email"
                                                                        value={formData.businessEmail}
                                                                        onChange={handleChange}
                                                                    />
                                                                    {errors.businessEmail && <small className="text-danger">{errors.businessEmail}</small>}
                                                                </div>
                                                                <div className="form-group">
                                                                    <label className="mb-1 form-label">Business Phone Number</label>
                                                                    <input
                                                                        name='businessPhoneNumber'
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="Business Phone Number"
                                                                        value={formatPhoneNumber(formData.businessPhoneNumber)}
                                                                        onChange={handleChange}
                                                                    />
                                                                    {errors.businessPhoneNumber && <small className="text-danger">{errors.businessPhoneNumber}</small>}
                                                                </div>
                                                            </div>
                                                            {/* <div className='form-group-row'>
                                                                <div className="form-group">
                                                                    <label className="mb-1 form-label">Business Category</label>
                                                                   
                                                                    <select
                                                                        name='businessCategory'
                                                                        className="form-control"
                                                                        
                                                                        value={formData.businessCategory}
                                                                        onChange={handleChange}
                                                                    >
                                                                        <option value="">Select Business Category</option>
                                                                        <option value="Car rental">Car rental</option>
                                                                        <option value="E-Commerce">E-Commerce</option>
                                                                        <option value="Lodging">Lodging</option>
                                                                        <option value="Mail order/ Telephone order">Mail order/ Telephone order</option>
                                                                        <option value="Petro">Petro</option>
                                                                        <option value="QSR">QSR</option>
                                                                        <option value="Restaurant">Restaurant</option>
                                                                        <option value="Retail">Retail</option>
                                                             
                                                                    </select>
                                                                </div>
                                                                <div className="form-group">
                                                                    <label htmlFor="business_type" className="mb-1 form-label">Business Type</label>
                                                                    <select
                                                                        name="businessType"
                                                                        id="business_type"
                                                                        className="form-control required"
                                                                        value={selectedType}
                                                                        onChange={(e) => setSelectedType(e.target.value)}
                                                                    >
                                                                        <option value="">Select Business Type</option>
                                                                        <option value="GOVERNMENT">Government</option>
                                                                        <option value="JOINT STOCK">Joint Stock</option>
                                                                        <option value="LIMITED">Limited</option>
                                                                        <option value="NON PROFIT ORG">Non Profit Org</option>
                                                                        <option value="PARTNERSHIP">Partnership</option>
                                                                        <option value="CORPORATION">Corporation</option>
                                                                        <option value="PUBLIC COMPANY">Public Company</option>
                                                                        <option value="SOLE PROPRIETOR">Sole Proprietor</option>
                                                                    </select>
                                                                </div>
                                                            </div> */}
                                                            <div className='form-group-row'>
                                                                <div className="form-group">
                                                                    <label className="mb-1 form-label">Business Address</label>
                                                                    <GoogleMapsComponent 
                                                                    value={addressInput}
                                                                    onChange={setAddressInput}
                                                                    onSelect={handleSelect}
                                                                    errorText={errors.businessAddress}
                                                                    />
                                                                </div>
                                                                <div className="form-group">
                                                                    <label className="mb-1 form-label">State</label>
                                                                    <input
                                                                        name='state'
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="State"
                                                                        value={contactAddress.state}
                                                                        onChange={(e) => setContactAddress({ ...contactAddress, state: e.target.value })}
                                                                    />
                                                                    {errors.state && <small className="text-danger">{errors.state}</small>}
                                                                </div>
                                                            </div>

                                                            <div className='form-group-row'>
                                                                <div className="form-group">
                                                                    <label className="mb-1 form-label">City</label>
                                                                    <input
                                                                        name='city'
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="City"
                                                                        value={contactAddress.city}
                                                                        onChange={(e) => setContactAddress({ ...contactAddress, city: e.target.value })}
                                                                    />
                                                                    {errors.city && <small className="text-danger">{errors.city}</small>}
                                                                </div>
                                                                <div className="form-group">
                                                                    <label className="mb-1 form-label">Country</label>
                                                                    <input
                                                                        name='country'
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="Country"
                                                                        value={contactAddress.country}
                                                                        onChange={(e) => setContactAddress({ ...contactAddress, country: e.target.value })}
                                                                    />
                                                                    {errors.country && <small className="text-danger">{errors.country}</small>}
                                                                </div>
                                                            </div>

                                                            <div className='form-group-row'>
                                                                <div className="form-group">
                                                                    <label className="mb-1 form-label">Zip Code</label>
                                                                    <input
                                                                        name='zipCode'
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="Zip Code"
                                                                        value={contactAddress.zipCode}
                                                                        onChange={(e) => setContactAddress({ ...contactAddress, zipCode: e.target.value })}
                                                                    />
                                                                    {errors.zipCode && <small className="text-danger">{errors.zipCode}</small>}
                                                                </div>
                                                                <div className="form-group">
                                                                    <label className="mb-1 form-label">Employer Identification Number / TAX ID</label>
                                                                    <input
                                                                        name='einOrTaxId'
                                                                        type="number"
                                                                        className="form-control"
                                                                        placeholder="Employer Identification Number / TAX ID"
                                                                        value={formData.einOrTaxId}
                                                                        onChange={handleChange}
                                                                    />
                                                                    {errors.einOrTaxId && <small className="text-danger">{errors.einOrTaxId}</small>}
                                                                </div>
                                                            </div>

                                                            <div className='form-group-row'>
                                                                <div className="form-group">
                                                                    <label className="mb-1 form-label">Social Security Number</label>
                                                                    <input
                                                                        name='socialSecurityNumber'
                                                                        type="number"
                                                                        className="form-control"
                                                                        placeholder="Social Security Number"
                                                                        value={formData.socialSecurityNumber}
                                                                        onChange={handleChange}
                                                                    />
                                                                    {errors.socialSecurityNumber && <small className="text-danger">{errors.socialSecurityNumber}</small>}
                                                                </div>
                                                                <div className="form-group">
                                                                    <label className="mb-1 form-label">Website</label>
                                                                    <input
                                                                        name='website'
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="Website"
                                                                        value={formData.website}
                                                                        onChange={handleChange}
                                                                    />
                                                                    {errors.website && <small className="text-danger">{errors.website}</small>}
                                                                </div>
                                                            </div>

                                                            <div className='form-group-row'>
                                                                <div className="form-group">
                                                                    <label className="mb-1 form-label">Established Date</label>
                                                                    <input
                                                                        name='establishedDate'
                                                                        type="date"
                                                                        className="form-control"
                                                                        placeholder="Established Date"
                                                                        value={formData.establishedDate}
                                                                        onChange={handleChange}
                                                                    />
                                                                    {errors.establishedDate && <small className="text-danger">{errors.establishedDate}</small>}
                                                                </div>
                                                            </div>
                                                            {/* <div className="form-group">
                                                                    <label className="mb-1 form-label">Industry</label>
                                                                    <select
                                                                        name='industry'
                                                                        className="form-control"
                                                                                                                                          value={formData.industry}
                                                                        onChange={handleChange}
                                                                    >
                                                                        <option value="">Select Account Type</option>
                                                                        {
                                                                            industry?.map((item,ind)=>{
                                                                                return(
                                                                                    <option value={item?.code} key={ind}>{item?.name}</option>

                                                                                )
                                                                            })
                                                                        }
                                                                        
                                                                    </select>
                                                                </div> */}
                                                            <div className='d-flex justify-content-center mt-5 mb-3 gap-2'>
                                                                <button type="button" className="btn btn-secondary" onClick={handlePrev}>Previous</button>
                                                                <button type="submit" className="btn btn-primary">Save and Continue</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ThirdForm;
