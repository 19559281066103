import React, { useEffect, useState } from "react";
import MainHeader from "../components/MainHeader";
import useFetch from "../hooks/useFetch";
import endPoints from "../constants";
import Select from "react-select";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { Bars } from "react-loader-spinner";
import Modal from "react-responsive-modal";
import PhoneInput from "react-phone-number-input/input";
import Sidebar from "../components/Sidebar";
import SideDefault from "../components/SideDefault";



const EditStaff = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [updateLoading, setUpdateLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);
    const [staffToEditDetails, setStaffToEditDetails] = useState(null);
    const [selected, setSelected] = useState([]);
    const [staffDetails, setStaffDetails] = useState({
        first_name: "",
        last_name: "",
        email: "",
        role_ids: "",
        phone: "",
        business_id: "",
        skysystemz_staff_role: null,
        permission_accounts: [],
    });

    const [deleteLoading, setDeleteLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [showSkySystmRole, setShowSkySystmRole] = useState(false);
    const [businessId, setBusinessId] = useState(null);
    const [count, setCount] = useState(0);
    const { data: staff_role, dataLoading: staff_role_loading } = useFetch(endPoints.GET_STAFF_ROLE);
    const { data: business, dataLoading: business_Loading } = useFetch(endPoints.ALL_BUSINESS);
    const { data: staff, dataLoading: staffLoading } = useFetch(`${endPoints.STAFFS}/${params?.staff_id?.split("_")[0]}`);
    const { data: skystm, dataLoading: skystem_Loading } = useFetch(`${endPoints.SKYSYSTEMZ_ROLE}?business_id=${businessId}`, count);
    const { data: myDetails, dataLoading: myDetailsLoading } = useFetch(`${endPoints.ME}`);
    const { data: accounts, dataLoading: accounts_Loading } = useFetch(endPoints.ALL_ACCOUNTS);

    const [checkEmail, setCheckEmail] = useState(false);
    const [showAccount, setShowAccount] = useState(false);
    const [account, setAccount] = useState(null);
    useEffect(() => {
        if (skystm?.roles?.length) {
            setShowSkySystmRole(true);
        }
    }, [skystm]);

    useEffect(() => {
        if (staff && myDetails) {
            setStaffToEditDetails(staff?.data?.attributes);
            if (myDetails?.email == staff?.data?.attributes?.email) {
                setCheckEmail(true);
            }
        }


    }, [staff, myDetails]);
    useEffect(() => {
        if (accounts) {
            let data = [];
            let allAccounts = [];
            accounts?.data?.forEach((itc) => {
                allAccounts.push({ value: itc?.attributes?.uuid, label: itc?.attributes?.label });
                if (
                    staffToEditDetails?.permission_accounts?.includes(itc?.id?.toString()) || staffToEditDetails?.permission_accounts?.includes(itc?.uuid)
                ) {
                    data.push({ value: itc?.attributes?.uuid, label: itc?.attributes?.label });
                }
            });


            if (data && allAccounts) {
                setStaffDetails({
                    ...staffDetails,
                    permission_accounts: data,
                });

                setSelected(data);
                setAccount(allAccounts);

            }
        }
    }, [accounts]);
    // useEffect(() => {
    //     let busID = business?.filter((item) => staffToEditDetails?.business_id==item.id)[0]
    //     setBusinessId(busID?.uuid);
    //     if(busID)   setCount(count+1)
    //     console.log("busomessss,", location.state);

    // }, [business])
    const isAlpha = (value) => /^[a-zA-Z]+$/.test(value);
    const isNumeric = (value) => /^\d+$/.test(value);
    const isValidEmail = (value) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
    const handleChange = (name, value) => {
        setStaffDetails({ ...staffDetails, [name]: value });

        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: "",
        }));

        if ((name === "first_name" || name === "last_name") && !isAlpha(value)) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: "Only letters are allowed",
            }));
            return;
        }

        if (name === "phone") {
            if (value?.length != 12) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    phone: "Phone number should be 10 digits"

                }));
                return;
            }
        }

        if (name === "email" && !isValidEmail(value)) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                email: "Invalid email format",
            }));
            return;
        }
        if (name == "business_id") {
            setCount(count + 1);
        }
        if (
            (name == "role_ids" && value == 1) ||
            (name == "role_ids" && value == 6)
        ) {
            setShowAccount(false);

        } else if (
            (name == "role_ids" && value == 4) ||
            (name == "role_ids" && value == 5)
        ) {
            setShowAccount(true);
        }
    };

    const selectAccount = (bId) => {
        let acc = business?.filter((item) => {
            if (bId == item?.id) {
                return item;
            }
        });

        let opt = acc[0]?.account?.map((item) => {
            return { value: item?.uuid, label: item?.label };
        });
        setAccount(opt);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let errors = {};
        if (!staffDetails.first_name) errors.first_name = "First name is required";
        if (!staffDetails.last_name) errors.last_name = "Last name is required";
        if (!staffDetails.email) errors.email = "Email is required";
        if (!staffDetails.phone) errors.phone = "Phone is required";
        if (!staffDetails.role_ids) errors.role_ids = "Role is required";
        if (!staffDetails.business_id) errors.business_id = "Business is required";
        if (
            staffDetails.business_id &&
            skystm?.roles?.length &&
            !staffDetails.skysystemz_staff_role
        )
            errors.skysystemz_staff_role = "Sky System Role is required";

        if (Object.keys(errors).length > 0) {
            setErrors(errors);
            return;
        }
        try {
            setUpdateLoading(true);
            let permisnAcconts = []
            if (['4', '5']?.includes(staffDetails?.role_ids)) {
                permisnAcconts = staffDetails?.permission_accounts?.map((item) => {
                    return item?.value;
                });

            }


            const data = {
                ...staffDetails,
                permission_accounts: permisnAcconts,
                phone: staffDetails?.phone?.slice(2),
            };

            const user = localStorage.getItem("skyfi");
            const tk = JSON.parse(user);
            const resp = await axios.put(
                `${process.env.REACT_APP_BASE_URL}${endPoints.STAFFS}/${staffToEditDetails?.uuid}`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${tk.access_token}`,
                    },
                }
            );
            if (resp?.status == 200) {
                toast.success("Staff Successfully Updated");
                setStaffDetails({
                    first_name: "",
                    last_name: "",
                    email: "",
                    role_ids: "",
                    phone: "",
                    business_id: "",
                    skysystemz_staff_role: null,
                    permission_accounts: [],
                });

                navigate("/staff-list");
                setUpdateLoading(false);
            }
        } catch (error) {
            setUpdateLoading(false);
            console.log("error====================", error);
            toast.error(error?.response?.data?.error);
            toast.error(error?.response?.data?.msg);
        }
    };
    const loading = () => {
        if (
            !skystem_Loading &&
            !business_Loading &&
            !staff_role_loading &&
            !staffLoading &&
            !accounts_Loading &&
            !myDetailsLoading
        ) {
            return false;
        } else return true;
    };
    const [cc, setcc] = useState(0);
    useEffect(() => {
        setStaffDetails({
            first_name: staffToEditDetails?.first_name,
            last_name: staffToEditDetails?.last_name,
            email: staffToEditDetails?.email,
            role_ids: staffToEditDetails?.role_ids,
            phone: `+1${staffToEditDetails?.phone}`,
            business_id: staffToEditDetails?.business_id,
            skysystemz_staff_role: staffToEditDetails?.skysystemz_staff_role,
            permission_accounts: [1, 6]?.includes(staffToEditDetails?.role_ids) ?? [],
        });

        if (
            staffToEditDetails?.role_ids == 1 ||
            staffToEditDetails?.role_ids == 6
        ) {
            setShowAccount(false);
        }
        if (
            staffToEditDetails?.role_ids == 4 ||
            staffToEditDetails?.role_ids == 5
        ) {
            setShowAccount(true);
        }
        setcc(cc + 1);
        let busID = business?.filter(
            (item) => staffToEditDetails?.business_id == item.id
        )[0];
        setBusinessId(busID?.uuid);
        if (busID) setCount(count + 1);
    }, [business?.length, staffToEditDetails]);


    const handleDelete = async () => {
        try {
            setDeleteLoading(true);
            const user = localStorage.getItem("skyfi");
            const tk = JSON.parse(user);
            const resp = await axios.delete(
                `${process.env.REACT_APP_BASE_URL}/${endPoints.STAFFS}/${staffToEditDetails?.uuid}`,
                {
                    headers: {
                        Authorization: `Bearer ${tk.access_token}`,
                    },
                }
            );
            setDeleteLoading(false);
            if (resp?.status == 200) {
                toast.success("Staff deleted");
                onOpenModal();
                navigate("/staff-list", { replace: true });
            }
        } catch (error) {
            setDeleteLoading(false);
            console.log(error);
            toast.error(error?.response?.data?.error);
            toast.error(error?.response?.data?.error[0]?.errorDesc);
        }
    };

    return (
        <>
            {/* <MainHeader /> */}
            {!loading() && (
                <div className="s-layout">
                    <SideDefault />
                    <main className="s-layout__content">
                        <div className="main-page">
                            <div className="row justify-content-center h-100 align-items-center">
                                <div className="col-lg-8 col-md-10">
                                    <div className="card login-part">
                                        <div className="authincation-content">
                                            <div className="row no-gutters">
                                                <div className="col-xl-12">
                                                    <div className="auth-form">
                                                        <div className="d-flex justify-content-between">
                                                            <h4 className="mb-4"><svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 24 24">
                                                                <g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5">
                                                                    <path d="M15.854 2.75H9.687A5.139 5.139 0 0 0 4.55 7.889v8.222c0 2.838 2.3 5.139 5.138 5.139h6.167c2.838 0 5.139-2.3 5.139-5.139V7.89c0-2.838-2.3-5.139-5.139-5.139M3.007 8.403H6.09m-3.083 7.194H6.09" />
                                                                    <path d="M16.67 16.745c0-1.653-1.843-2.996-3.495-2.996c-1.653 0-3.495 1.343-3.495 2.995m3.495-5.376a2.348 2.348 0 1 0 0-4.696a2.348 2.348 0 0 0 0 4.696" />
                                                                </g>
                                                            </svg> Update Staff</h4>
                                                            <div className="add-btn cursor-pointer  ">
                                                                <a className="mb-4 " onClick={onOpenModal}>
                                                                    Delete
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <form onSubmit={handleSubmit} className="mt-4">
                                                            <div className="form-group">
                                                                <label className="mb-1 form-label">
                                                                    First Name
                                                                </label>
                                                                <input
                                                                    value={staffDetails?.first_name}
                                                                    onChange={(e) =>
                                                                        handleChange("first_name", e.target.value)
                                                                    }
                                                                    name="first_name"
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="First Name"
                                                                />
                                                                {errors.first_name && (
                                                                    <span className="text-danger">
                                                                        {errors.first_name}
                                                                    </span>
                                                                )}
                                                            </div>
                                                            <div className="form-group">
                                                                <label className="mb-1 form-label">
                                                                    Last Name
                                                                </label>
                                                                <input
                                                                    value={staffDetails?.last_name}
                                                                    onChange={(e) =>
                                                                        handleChange("last_name", e.target.value)
                                                                    }
                                                                    name="last_name"
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Last Name"
                                                                />
                                                                {errors.last_name && (
                                                                    <span className="text-danger">
                                                                        {errors.last_name}
                                                                    </span>
                                                                )}
                                                            </div>
                                                            <div className="form-group">
                                                                <label className="mb-1 form-label">
                                                                    Email
                                                                </label>
                                                                {checkEmail ?
                                                                    <span className="form-control">{staffDetails?.email}</span> :
                                                                    <input
                                                                        value={staffDetails?.email}
                                                                        onChange={(e) =>
                                                                            handleChange("email", e.target.value)
                                                                        }
                                                                        name="email"
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="Enter Email"
                                                                    />}
                                                                {errors.email && (
                                                                    <span className="text-danger">
                                                                        {errors.email}
                                                                    </span>
                                                                )}
                                                            </div>
                                                            <div className="form-group">
                                                                <label className="mb-1 form-label">
                                                                    Phone
                                                                </label>

                                                                <PhoneInput
                                                                    value={`${staffDetails?.phone}`}
                                                                    placeholder="Mobile number"
                                                                    country="US"
                                                                    className="form-control"
                                                                    onChange={(e) => {
                                                                        handleChange("phone", e);
                                                                    }}
                                                                />
                                                                {errors.phone && (
                                                                    <span className="text-danger">
                                                                        {errors.phone}
                                                                    </span>
                                                                )}
                                                            </div>
                                                            <div className="form-group">
                                                                <label className="mb-1 form-label">
                                                                    Role
                                                                </label>
                                                                <select
                                                                    value={staffDetails?.role_ids}
                                                                    onChange={(e) => { handleChange("role_ids", e.target.value); }
                                                                    }
                                                                    name="role_ids"
                                                                    className="form-control"
                                                                    aria-label="Default select example"
                                                                >
                                                                    <option>Select Role </option>
                                                                    {staff_role?.length &&
                                                                        staff_role?.map((item) => {
                                                                            return (
                                                                                <option
                                                                                    value={item?.id}
                                                                                    key={item?.id}
                                                                                >
                                                                                    {item?.name}
                                                                                </option>
                                                                            );
                                                                        })}
                                                                </select>
                                                                {errors.role_ids && (
                                                                    <span className="text-danger">
                                                                        {errors.role_ids}
                                                                    </span>
                                                                )}
                                                            </div>
                                                            <div className="form-group">
                                                                <label className="mb-1 form-label">
                                                                    Business
                                                                </label>
                                                                <select
                                                                    value={staffDetails?.business_id}
                                                                    name="business_id"
                                                                    className="form-control"
                                                                    aria-label="Default select example"
                                                                    onChange={(e) => {

                                                                        handleChange(
                                                                            "business_id",
                                                                            e.target.value
                                                                        );
                                                                    }}
                                                                >
                                                                    {/* <select
                                                                        value={staffDetails?.business_id}
                                                                        name="business_id"
                                                                        className="form-control"
                                                                        aria-label="Default select example"
                                                                        onChange={(e) => {
                                                                            selectAccount(e.target.value);
                                                                            handleChange(
                                                                                "business_id",
                                                                                e.target.value
                                                                            );
                                                                        }}
                                                                    > */}
                                                                    <option>Select Business </option>
                                                                    {business?.length &&
                                                                        business?.map((item) => {
                                                                            return (
                                                                                <option
                                                                                    value={item?.id}
                                                                                    key={item?.id}
                                                                                >
                                                                                    {item?.legal_name}
                                                                                </option>
                                                                            );
                                                                        })}
                                                                </select>

                                                                {errors.business_id && (
                                                                    <span className="text-danger">
                                                                        {errors.business_id}
                                                                    </span>
                                                                )}
                                                            </div>


                                                            {showSkySystmRole && (
                                                                <div className="form-group">
                                                                    <label className="mb-1 form-label">SkySystemz</label>
                                                                    {!loading() && (
                                                                        <Select
                                                                            isDisabled={!showSkySystmRole}
                                                                            options={skystm?.roles?.map((item) => ({
                                                                                value: item?.role_id,
                                                                                label: item?.role_name,
                                                                            }))}
                                                                            value={staffDetails?.skysystemz_staff_role ? {
                                                                                value: staffDetails.skysystemz_staff_role,
                                                                                label: skystm?.roles?.find(role => role.role_id === staffDetails.skysystemz_staff_role)?.role_name
                                                                            } : null}
                                                                            onChange={(selectedOption) => {
                                                                                handleChange("skysystemz_staff_role", selectedOption?.value);
                                                                            }}
                                                                            placeholder="Select Role"
                                                                        />
                                                                    )}
                                                                    {errors.skysystemz_staff_role && (
                                                                        <span className="text-danger">{errors.skysystemz_staff_role}</span>
                                                                    )}
                                                                </div>
                                                            )}

                                                            <div className="form-group">
                                                                <label className="mb-1 form-label">Account</label>
                                                                {!loading() && (
                                                                    <Select
                                                                        isDisabled={!showAccount}
                                                                        options={business?.map((item) => ({
                                                                            label: <b style={{ fontWeight: 600 }}>{item.legal_name}</b>,
                                                                            options: item?.account?.map((acc) => ({
                                                                                value: acc.uuid,
                                                                                label: (
                                                                                    <span className="option-label" >
                                                                                        {acc.label}
                                                                                    </span>
                                                                                ),
                                                                            })),
                                                                        }))}
                                                                        isMulti
                                                                        value={staffDetails.permission_accounts}
                                                                        onChange={(e) => {
                                                                            setStaffDetails({
                                                                                ...staffDetails,
                                                                                permission_accounts: e,
                                                                            });
                                                                        }}
                                                                    />
                                                                )}
                                                            </div>


                                                            <div className="text-center">
                                                                <button type="submit" className="btn" disabled={updateLoading}>
                                                                    Update
                                                                </button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            )}

            <Modal open={open} onClose={onCloseModal} center>
                <div className="p-5">
                    <div className="">
                        <h4>Do you want to delete this staff member?</h4>
                        <div className="text-center">
                            <button className="btn-save" onClick={onOpenModal}>
                                Cancel
                            </button>
                            {/* <button className='btn btn-primary mx-3'  > Sure</button> */}
                            <button
                                className="btn-cancel mx-3"
                                onClick={handleDelete}
                                disabled={deleteLoading}
                            >
                                {deleteLoading ? "Loading" : "Sure"}
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
            <Bars
                height="80"
                width="80"
                color="#39AFFF"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass="bars"
                visible={loading()}
            />
        </>
    );
};




export default EditStaff;
