import React from 'react'
import SideDefault from '../components/SideDefault';
import { Link, useNavigate } from 'react-router-dom';

const LinkAccount = () => {
    const navigate = useNavigate();
    const types = [
        {
            id: 0,
            title: "Link external accounts",
            description: "Instantly with Plaid using your bank’s log in credentials",
            action: () => {
                navigate('/link-external-account', { state: 'getPlaid' })
            }
        },
        {
            id: 1,
            title: "Link external accounts",
            description: "Instantly using micro deposits with RTP/FedNow*",
            action: () => {
                navigate('/link-external-account', { state: 'notGetPlaid' })
            }
        },
        {
            id: 2,
            title: "Check account eligibility",
            description: "For instant verification with RTP / FedNow.",
            action: () => {
                navigate('/link-account-eligibility')
            }
        }
    ]
    return (
        <div className="s-layout" >
            < SideDefault />
            <main className="s-layout__content" >
                <div className="main-page">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card plan-list">
                                <div className="card-header d-sm-flex flex-wrap d-block pb-0 border-0">
                                    <div className="me-auto pe-3 my-4">
                                        <h4 className="text-black fs-26 mb-3"><svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24">
                                            <g fill="none">
                                                <path d="M24 0v24H0V0h24ZM12.594 23.258l-.012.002l-.071.035l-.02.004l-.014-.004l-.071-.036c-.01-.003-.019 0-.024.006l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.016-.018Zm.264-.113l-.014.002l-.184.093l-.01.01l-.003.011l.018.43l.005.012l.008.008l.201.092c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.003-.011l.018-.43l-.003-.012l-.01-.01l-.184-.092Z" />
                                                <path fill="currentColor" d="M20 9a1 1 0 0 1 1 1v1a8 8 0 0 1-8 8H9.414l.793.793a1 1 0 0 1-1.414 1.414l-2.496-2.496a.997.997 0 0 1-.287-.567L6 17.991a.996.996 0 0 1 .237-.638l.056-.06l2.5-2.5a1 1 0 0 1 1.414 1.414L9.414 17H13a6 6 0 0 0 6-6v-1a1 1 0 0 1 1-1Zm-4.793-6.207l2.5 2.5a1 1 0 0 1 0 1.414l-2.5 2.5a1 1 0 1 1-1.414-1.414L14.586 7H11a6 6 0 0 0-6 6v1a1 1 0 1 1-2 0v-1a8 8 0 0 1 8-8h3.586l-.793-.793a1 1 0 0 1 1.414-1.414Z" />
                                            </g>
                                        </svg> Link Account</h4>
                                    </div>
                                    <div className="col-xl-12 mt-3 link-act-main">
                                        <h4>Link Your External Account</h4>
                                        {types?.map((item) => (
                                            <ul className="tab-links stf-list link-act" key={item.id}>
                                                <li className='cursor-pointer' onClick={item.action}>
                                                    <Link>
                                                        <div>
                                                            <h5>{item.title}</h5>
                                                            <p>{item.description}</p>
                                                        </div>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 512 512">
                                                            <path fill="currentColor" d="m359.873 121.377l-22.627 22.627l95.997 95.997H16v32.001h417.24l-95.994 95.994l22.627 22.627L494.498 256L359.873 121.377z" />
                                                        </svg>
                                                    </Link>
                                                </li>
                                            </ul>
                                        ))}
                                        <p>*This option may take 3-5 business days if your external account is not compatible with RTP or FedNow.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default LinkAccount
