import React, { useState } from 'react'
import SideDefault from '../components/SideDefault'
import axios from 'axios'
import endPoints from '../constants'
import { toast } from 'react-toastify'
import { Bars } from 'react-loader-spinner'
import { useNavigate } from 'react-router-dom'

const LinkAccountEligibility = () => {
    const user = localStorage.getItem("skyfi");
    const tk = JSON.parse(user);
    const navigate = useNavigate();
    const [routing_number, setRouting_number] = useState('')
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const isNumeric = (value) => /^\d+$/.test(value);
    const handleChange = (e) => {
        const { value } = e.target;
        if (value && !isNumeric(value)) {
            setError('number digit is required');
            return;
        }
        else {
            setRouting_number(value)
        }
        setError('');
    }

    const checkEligibility = async (e) => {
        e.preventDefault();
        if (!routing_number) {
            setError('routing number is required');
            return;
        }
        if (routing_number.length != 9) {
            setError('routing number should be 9 digits');
            return;
        }
        try {
            setLoading(true)
            const resp = await axios.post(`${process.env.REACT_APP_BASE_URL}${endPoints.CHECK_ELIGIBILITY}`, { routing_number },
                {
                    headers: {
                        'Authorization': `Bearer ${tk.access_token}`
                    }
                }
            );
            if (resp?.status === 200) {
                console.log("resp", resp?.data?.eligibility_status);
                if (resp?.data?.eligibility_status?.toLowerCase().includes("not eligible")) {
                    toast.error(resp?.data?.eligibility_status)
                    navigate("/external-account");
                } else {
                    toast.success(resp?.data?.eligibility_status)
                    navigate("/external-account");
                }
            }

        } catch (error) {
            console.log(error);
            toast.error(error.response.message || error?.response?.data?.error || "Something went wrong");

        } finally {
            setLoading(false)
        }
    }
    return (
        <>
            {!loading ?
                <div className="s-layout" >
                    < SideDefault />
                    <main className="s-layout__content">
                        <div className="main-page">
                            <div className="row justify-content-center h-100 align-items-center">
                                <div className="col-lg-8 col-md-10">
                                    <div className="card plan-list account-eligibility">
                                        <div className="card-header d-sm-flex flex-wrap d-block pb-0 border-0">
                                            <div className="me-auto">
                                                <h4 className="text-black fs-26 mb-3"> <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 20 20">
                                                    <path fill="currentColor" d="M9.993 10.573a4.5 4.5 0 1 0 0-9a4.5 4.5 0 0 0 0 9ZM10 0a6 6 0 0 1 3.04 11.174c3.688 1.11 6.458 4.218 6.955 8.078c.047.367-.226.7-.61.745c-.383.045-.733-.215-.78-.582c-.54-4.19-4.169-7.345-8.57-7.345c-4.425 0-8.101 3.161-8.64 7.345c-.047.367-.397.627-.78.582c-.384-.045-.657-.378-.61-.745c.496-3.844 3.281-6.948 6.975-8.068A6 6 0 0 1 10 0Z" />
                                                </svg> Link Account</h4>
                                            </div>
                                            <div className="col-xl-12 mt-3 link-act-main">
                                                <div className="form-group">
                                                    <label className="form-label">Routing Number</label>
                                                    <input
                                                        className={`form-control`}
                                                        name='routing_number'
                                                        type="text"
                                                        placeholder="routing number"
                                                        onChange={handleChange}
                                                        value={routing_number}
                                                        maxLength={9}
                                                    />
                                                    {error && <div className="text-danger">{error}</div>}
                                                </div>
                                                <div className="create-account" >
                                                    <button onClick={checkEligibility}>Submit</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div >
                : <Bars
                    height="80"
                    width="80"
                    color="#39AFFF"
                    ariaLabel="bars-loading"
                    wrapperStyle={{}}
                    wrapperClass="bars"
                    visible={loading}
                />
            }
        </>
    )
}

export default LinkAccountEligibility
