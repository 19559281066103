import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import axios from 'axios';
import endPoints from '../constants';
import { toast } from 'react-toastify';
import AcccountReady from '../components/AcccountReady';
import { Bars } from 'react-loader-spinner';
import SideDefault from '../components/SideDefault';

const AddingAccount = () => {
    const location = useLocation();
    const [accLabel, setAccLabel] = useState("");
    const slug = useParams();
    const navigate = useNavigate();
    const [accLabelError, setAccLabelError] = useState(false);
    const [acceptedTerms, setAcceptedTerms] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showAccountReady, setShowAccountReady] = useState(false);
    const [accntRedyData, setAccntRedyData] = useState(null);
    let busines_id = slug?.b_name?.split("_")[0];

    const user = localStorage.getItem("skyfi");
    const tk = JSON.parse(user);
    const verify_consent = async () => {
        try {
            setLoading(true)
            const resp = await axios.patch(`${process.env.REACT_APP_BASE_URL}${endPoints.UPDATE_USER_CONCENT}`, {},
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${tk.access_token}`
                    }
                }
            );
            if (resp?.status === 200) {
                toast.success(resp?.data?.message);
            }
        } catch (error) {
            console.log(error);
            toast.error(error.response.message || error?.response?.data?.error || "Something went wrong");

        } finally {
            setLoading(false)
        }
    }


    const handleSubmit = async (e) => {
        e.preventDefault();
        if (accLabel.trim() === "") {
            setAccLabelError(true);
            return;
        }
        if (!acceptedTerms) {
            toast.error("Please accept terms and conditions");
            return;
        }

        try {
            setLoading(true);

            let data = {
                label: `${accLabel}`,
                acceptedTerms: `${acceptedTerms}`,
                business_id: `${busines_id}`,
            };

            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}${endPoints.LINK_ACCOUNT}`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${tk.access_token}`,
                    },
                }
            );
            if (response?.status == 201) {
                if (location?.state?.verifyPolicy === false) {
                    await verify_consent()
                }
                toast.success('Account linked successfully!');
                setAccLabel("");
                setAccntRedyData(response?.data?.data?.attributes);
                setShowAccountReady(true);
                setAcceptedTerms(false);

            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            toast.error(error?.response?.data?.error);
            toast.error(error?.response?.data?.error[0]?.errorDesc);
            console.log("error", error);
        }
    };

    useEffect(() => {
        if (!location?.state?.flag) {
            navigate('/');
        }
    }, [location?.state?.flag])
    return (
        <>
            {!showAccountReady && !loading &&

                <div className="s-layout">
                    <SideDefault />
                    <main className="s-layout__content">
                        <div className="main-page">
                            <div className="row justify-content-center h-100 align-items-center">
                                <div className="col-lg-8 col-md-10">
                                    <div className="card login-part">
                                        <div className="authincation-content">
                                            <div className="row no-gutters">
                                                <div className="col-xl-12">
                                                    <div className="auth-form">
                                                        <h4 className="mb-4">Add Account</h4>
                                                        <form onSubmit={handleSubmit} className="mt-4">
                                                            <div className="form-group">
                                                                <label className="mb-1 form-label">

                                                                    Account Label
                                                                </label>
                                                                <input
                                                                    value={accLabel}
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Account Label"
                                                                    onChange={(e) => { setAccLabel(e.target.value); setAccLabelError(false) }}
                                                                />
                                                                {accLabelError && <small className="text-danger">Account Label Requred!</small>}
                                                            </div>
                                                            <div className="i-agree">
                                                                <input
                                                                    type="checkbox"
                                                                    id="agree"
                                                                    defaultValue="agree"
                                                                    checked={acceptedTerms}
                                                                    onChange={(e) => { setAcceptedTerms(e.target.checked) }}

                                                                />
                                                                <label htmlFor="agree">
                                                                    By opening an account, I agree to all privacy
                                                                    policies, agreements and terms and conditions
                                                                </label>
                                                            </div>
                                                            <div className="sky-account">
                                                                <a href="https://www.skyfichecking.com/disclosures" target="_blank" className="cursor-pointer">SkyFi Account</a>
                                                            </div>
                                                            <div className="create-account mt-5">
                                                                <button disabled={loading}>{loading ? "Loading..." : "Proceed"}</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            }

            {showAccountReady &&
                <AcccountReady data={accntRedyData} business_id={busines_id} />}

            <Bars
                height="80"
                width="80"
                color="#39AFFF"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass="bars"
                visible={loading}
            />
        </>
    )
}

export default AddingAccount