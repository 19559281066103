import { useEffect, useState } from "react";
import PlacesAutocomplete from "react-places-autocomplete";

const GoogleMapsComponent = ({
  value,
  errorText ,
  onChange,
  onSelect,
  placeholder = "Enter Address 1",
  name = "address1"
}) => {
    const [isApiLoaded, setIsApiLoaded] = useState(false);

    useEffect(() => {
        // Dynamically load Google Maps API script
        const loadGoogleMapsScript = () => {
          const script = document.createElement('script');
          script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}&libraries=places`;
          script.async = true;
          script.defer = true;
          script.onload = () => {
            console.log("Google Maps API Loaded");
            setIsApiLoaded(true);
          };
          document.body.appendChild(script);
    
          return () => {
            document.body.removeChild(script);
          };
        };
    
        console.log("Google component loading...");
        
        loadGoogleMapsScript();
      }, []);


      // If the API isn't loaded yet, you can render a loading spinner or some placeholder
  if (!isApiLoaded) {
    return <div>Loading Google Maps...</div>;
  }

  return (
    <div>
      <PlacesAutocomplete value={value} onChange={onChange} onSelect={onSelect}>
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <input
              {...getInputProps({
                placeholder: placeholder,
                className: "form-control",
                name: name,
              })}
            />
            {errorText && <small className="text-danger">{errorText}</small>}
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion, idd) => {
                const className = "cursor-pointer";
                const style = {
                  backgroundColor: suggestion.active ? "#41B6E6" : "#fff",
                  border: suggestion.active
                    ? "1px solid #41B6E6"
                    : "1px solid #ccc",
                  width: "410px",
                  marginLeft: "10px",
                  marginTop: "5px",
                };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span key={idd} className="">
                      {suggestion.description}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    </div>
  );
};


export default GoogleMapsComponent