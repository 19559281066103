import React, { useEffect, useState } from 'react';
import MainHeader from '../components/MainHeader';
import useFetch from '../hooks/useFetch';
import endPoints from '../constants';
import Select from 'react-select';
import axios from 'axios';

import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Bars } from 'react-loader-spinner';
import PhoneInput from 'react-phone-number-input/input'
import Sidebar from '../components/Sidebar';
import SideDefault from '../components/SideDefault';

const AddStaff = () => {
    const navigate = useNavigate();
    const [staffDetails, setStaffDetails] = useState({
        "first_name": "",
        "last_name": "",
        "email": "",
        "role_ids": "",
        "phone": "",
        "business_id": "",
        "skysystemz_staff_role": null,
        "permission_accounts": [],

    });
    const [errors, setErrors] = useState({})
    const [count, setCount] = useState(0);
    const [showSkySystmRole, setShowSkySystmRole] = useState(false);
    const [showAccount, setShowAccount] = useState(false);
    const [account, setAccount] = useState(null);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [selectedBusinessId, setSelectedBusinessId] = useState(null);
    const { data: staff_role, dataLoading: staff_role_loading } = useFetch(endPoints.GET_STAFF_ROLE);
    const { data: business, dataLoading: business_Loading } = useFetch(endPoints.ALL_BUSINESS);
    const { data: accounts, dataLoading: accounts_Loading } = useFetch(endPoints.ALL_ACCOUNTS);
    const { data: skystm, dataLoading: skystem_Loading } = useFetch(`${endPoints.SKYSYSTEMZ_ROLE}?business_id=${selectedBusinessId}`, count);

    useEffect(() => {
        if (skystm?.roles?.length) {
            setShowSkySystmRole(true);
        }
        if (accounts) {

            let opt = accounts?.data?.map((item) => {
                return { value: item?.attributes?.uuid, label: item?.attributes?.label }
            })
            setAccount(opt)
        }
    }, [skystm, accounts])

    const isAlpha = (value) => /^[a-zA-Z]+$/.test(value);
    const isNumeric = (value) => /^\d+$/.test(value);
    const isValidEmail = (value) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);


    const handleChange = (name, value) => {

        setStaffDetails({ ...staffDetails, [name]: value });

        // Clear errors for the specific field
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: ""
        }));


        if ((name === "first_name" || name === "last_name") && !isAlpha(value)) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: "Only letters are allowed"
            }));
            return;
        }

        if (name === "phone") {

            if (value.length != 12) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    phone: "Phone number should be 10 digits"

                }));
                return;
            }
        }

        if (name === "email" && !isValidEmail(value)) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                email: "Invalid email format"
            }));
            return;
        }
        if (name == "business_id" && value != "") {
            setSelectedBusinessId(value);
            setCount(count + 1);
        }
        if ((name == "role_ids" && value == 1) || (name == "role_ids" && value == 6)) {
            setShowAccount(false)
        }
        else if ((name == "role_ids" && value == 4) || (name == "role_ids" && value == 5)) {
            setShowAccount(true)
        }
    }

    // const selectAccount = (bId) => {
    //     let acc = business?.filter((item) => {
    //         if (bId == item?.uuid) {
    //             return item
    //         }
    //     })

    //     let opt = acc[0]?.account?.map((item) => {
    //         return { value: item?.uuid, label: item?.label }
    //     })
    //     setAccount(opt)
    // }

    const handleSubmit = async (e) => {
        e.preventDefault();
        let errors = {};
        if (!staffDetails.first_name) errors.first_name = "First name is required";
        if (!staffDetails.last_name) errors.last_name = "Last name is required";
        if (!staffDetails.email) errors.email = "Email is required";
        if (!staffDetails.phone) errors.phone = "Phone is required";
        if (!staffDetails.role_ids) errors.role_ids = "Role is required";
        if (!selectedBusinessId) errors.business_id = "Business is required";
        if (staffDetails.business_id && skystm?.roles?.length && !staffDetails.skysystemz_staff_role) errors.skysystemz_staff_role = "Sky System Role is required";
        if (Object.keys(errors).length > 0) {
            setErrors(errors);
            return;
        }

        try {
            setSubmitLoading(true);
            let permisnAcconts = []
            if (['4', '5']?.includes(staffDetails?.role_ids)) {
                permisnAcconts = staffDetails?.permission_accounts?.map((item) => {
                    return item?.value;
                });

            }
            // let permisnAcconts = staffDetails?.permission_accounts?.map((item) => {
            //     return item?.value
            // })

            const data = {
                ...staffDetails,
                permission_accounts: permisnAcconts,
                phone: staffDetails?.phone?.slice(2), business_id: selectedBusinessId
            }


            const user = localStorage.getItem("skyfi");
            const tk = JSON.parse(user);
            const resp = await axios.post(`${process.env.REACT_APP_BASE_URL}${endPoints.STAFFS}`, data,
                {
                    headers: {
                        'Authorization': `Bearer ${tk.access_token}`
                    }
                }
            );

            if (resp?.status == 200) {
                toast.success("Staff Successfully Created");
                setStaffDetails({
                    "first_name": "",
                    "last_name": "",
                    "email": "",
                    "role_ids": "",
                    "phone": "",
                    "business_id": "",
                    "skysystemz_staff_role": null,
                    "permission_accounts": [],

                });
                navigate("/staff-list");
            }
            setSubmitLoading(false);

        } catch (error) {
            setSubmitLoading(false);
            console.log(error);
            toast.error(error?.response?.data?.error);
            toast.error(error?.response?.data?.error[0]?.errorDesc);
        }
    }

    const comp_loading = () => {
        if (staff_role_loading || business_Loading || accounts_Loading) {
            return true
        }
        else return false
    }

    return (
        <>
            {/* <MainHeader /> */}
            {!comp_loading() &&
                <div className="s-layout">
                    <SideDefault />
                    <main className="s-layout__content">
                        <div className="main-page">
                            <div className="row justify-content-center h-100 align-items-center">
                                <div className="col-lg-8 col-md-10">
                                    <div className="card login-part">
                                        <div className="authincation-content">
                                            <div className="row no-gutters">
                                                <div className="col-xl-12">
                                                    <div className="auth-form">
                                                        <h4 className="mb-4"><svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 24 24">
                                                <g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5">
                                                    <path d="M15.854 2.75H9.687A5.139 5.139 0 0 0 4.55 7.889v8.222c0 2.838 2.3 5.139 5.138 5.139h6.167c2.838 0 5.139-2.3 5.139-5.139V7.89c0-2.838-2.3-5.139-5.139-5.139M3.007 8.403H6.09m-3.083 7.194H6.09" />
                                                    <path d="M16.67 16.745c0-1.653-1.843-2.996-3.495-2.996c-1.653 0-3.495 1.343-3.495 2.995m3.495-5.376a2.348 2.348 0 1 0 0-4.696a2.348 2.348 0 0 0 0 4.696" />
                                                </g>
                                            </svg> Add Staff</h4>
                                                        <form onSubmit={handleSubmit} className="mt-4">
                                                            <div className="form-group">
                                                                <label className="mb-1 form-label"> First Name </label>
                                                                <input
                                                                    onChange={(e) => handleChange("first_name", e.target.value)}
                                                                    name="first_name"
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="First Name"
                                                                />
                                                                {errors.first_name && (
                                                                    <span className="text-danger">{errors.first_name}</span>
                                                                )}
                                                            </div>
                                                            <div className="form-group">
                                                                <label className="mb-1 form-label"> Last Name</label>
                                                                <input
                                                                    onChange={(e) => handleChange("last_name", e.target.value)}
                                                                    name="last_name"
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Last Name"
                                                                />
                                                                {errors.last_name && (
                                                                    <span className="text-danger">{errors.last_name}</span>
                                                                )}
                                                            </div>
                                                            <div className="form-group">
                                                                <label className="mb-1 form-label"> Email</label>
                                                                <input
                                                                    onChange={(e) => handleChange("email", e.target.value)}
                                                                    name="email"
                                                                    type="email"
                                                                    className="form-control"
                                                                    placeholder="Enter Email"
                                                                />
                                                                {errors.email && (
                                                                    <span className="text-danger">{errors.email}</span>
                                                                )}
                                                            </div>
                                                            <div className="form-group">
                                                                <label className="mb-1 form-label"> Phone</label>
                                                                {/* <input
                                                                    onChange={(e) => handleChange("phone", e.target.value)}
                                                                    name="phone"
                                                                    type="number"
                                                                    className="form-control"
                                                                    placeholder="Enter Phone"
                                                                /> */}
                                                                <PhoneInput
                                                                    placeholder="Mobile number"
                                                                    country="US"
                                                                    className="form-control"

                                                                    onChange={(e) => { handleChange("phone", e) }} />
                                                                {errors.phone && (
                                                                    <span className="text-danger">{errors.phone}</span>
                                                                )}
                                                            </div>
                                                            <div className="form-group">
                                                                <label className="mb-1 form-label"> Role </label>
                                                                <select
                                                                    onChange={(e) => handleChange("role_ids", e.target.value)}
                                                                    name='role_ids'
                                                                    className="form-control"
                                                                    aria-label="Default select example"
                                                                >
                                                                    <option selected="">Select Role </option>
                                                                    {staff_role?.length > 0 && staff_role?.map((item) => {
                                                                        return (
                                                                            <option value={item?.id} key={item?.id}>{item?.name}</option>
                                                                        )
                                                                    })}
                                                                </select>
                                                                {errors.role_ids && (
                                                                    <span className="text-danger">{errors.role_ids}</span>
                                                                )}
                                                            </div>
                                                            <div className="form-group">
                                                                <label className="mb-1 form-label"> Business </label>
                                                                <select
                                                                    name='business_id'
                                                                    className="form-control"
                                                                    aria-label="Default select example"
                                                                    onChange={(e) => { handleChange("business_id", e.target.value); }}
                                                                >
                                                                    <option selected="" value={""}>Select Business </option>
                                                                    {business?.length > 0 && business?.map((item) => {
                                                                        return (
                                                                            <option value={item?.uuid} key={item?.id}>{item?.legal_name}</option>
                                                                        )
                                                                    })}
                                                                </select>
                                                                {/* <select
                                                                    name='business_id'
                                                                    className="form-control"
                                                                    aria-label="Default select example"
                                                                    onChange={(e) => { selectAccount(e.target.value); handleChange("business_id", e.target.value); setStaffDetails({ ...staffDetails, permission_accounts: [] }) }}
                                                                >
                                                                    <option selected="">Select Business </option>
                                                                    {business?.length > 0 && business?.map((item) => {
                                                                        return (
                                                                            <option value={item?.uuid} key={item?.id}>{item?.legal_name}</option>
                                                                        )
                                                                    })}
                                                                </select> */}
                                                                {errors.business_id && (
                                                                    <span className="text-danger">{errors.business_id}</span>
                                                                )}
                                                            </div>
                                                            {showSkySystmRole && <div className="form-group">
                                                                <label className="mb-1 form-label"> SkySystemz </label>
                                                                <select
                                                                    onChange={(e) => handleChange("skysystemz_staff_role", e.target.value)}
                                                                    name='skysystemz_staff_role'
                                                                    className="form-control"
                                                                    aria-label="Default select example"
                                                                >
                                                                    <option selected="">Select Role </option>
                                                                    {skystm?.roles?.length > 0 && skystm?.roles?.map((item, id) => {
                                                                        return (
                                                                            <option value={item?.role_id} key={id}>{item?.role_name}</option>
                                                                        )
                                                                    })}
                                                                </select>
                                                                {errors.skysystemz_staff_role && (
                                                                    <span className="text-danger">{errors.skysystemz_staff_role}</span>
                                                                )}
                                                            </div>}
                                                            <div className="form-group">
                                                                <label className="mb-1 form-label"> Account </label>
                                                                <Select isDisabled={!showAccount} isLoading={skystem_Loading} value={staffDetails?.permission_accounts} options={account ?? []} isMulti isSearchable={false} onChange={(e) => { setStaffDetails({ ...staffDetails, permission_accounts: e }) }} className="" />
                                                            </div>
                                                            <div className="text-center">
                                                                <button type="submit" disabled={submitLoading} className="btn">
                                                                    {submitLoading ? "Loading" : " Submit"}
                                                                </button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            }
            <Bars
                height="80"
                width="80"
                color="#39AFFF"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass="bars"
                visible={comp_loading()}
            />
        </>
    )
}

export default AddStaff;
