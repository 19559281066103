import React, { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import PhoneInput from 'react-phone-number-input/input'
import SideDefault from '../components/SideDefault';
const AddContact = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    const [cntData, setCntData] = useState({
        last_name: "",
        first_name: "",
        email: "",
    });

    const [phone, setPhone] = useState("")
    const [account_id, setAccount_id] = useState('')
    const [routing_number, setRouting_number] = useState("")
    const [errors, setErrors] = useState({});

    const isAlpha = (value) => /^[A-Za-z\s]*$/.test(value);
    const isNumeric = (value) => /^\d+$/.test(value);
    const isValidEmail = (value) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
    const handleChange = (name, value) => {
        if ((name === "first_name" || name === "last_name") && !isAlpha(value)) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: "Only letters are allowed"
            }));
            return;
        } else {
            setCntData({ ...cntData, [name]: value });
        }

        if (name === "email") {
            setCntData({ ...cntData, email: value });
            if (value && !isValidEmail(value)) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [name]: "Invalid email format"
                }));
                return;
            }
        }

        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: ""
        }));

    };

    const handlePhoneChange = (value) => {
        if (value && value?.length != 12) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                phone: "Phone number should be 10 digits"
            }));
            return;
        }
        else {
            setPhone(value);
        }
        setErrors((prevErrors) => ({
            ...prevErrors,
            phone: ""
        }));
    };

    const handleAccountChange = (e) => {
        const { value } = e.target;
        if (value && !isNumeric(value)) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                account_id: "number digit is required"
            }));
            return;
        }
        else {
            setAccount_id(value);
        }

        setErrors((prevErrors) => ({
            ...prevErrors,
            account_id: ""
        }));
    };

    const handleRoutingChange = (value) => {
        if (value && !isNumeric(value)) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                routing_number: "number digit is required"
            }));
            return;
        }
        else {
            setRouting_number(value);
        }
        setErrors((prevErrors) => ({
            ...prevErrors,
            routing_number: ""
        }));
    };

    const validate = () => {
        let validationErrors = { ...errors };
        if (!cntData.first_name) {
            validationErrors.first_name = "First Name is required";
        }

        if (!cntData.last_name) {
            validationErrors.last_name = "Last Name is required";
        }

        if (!cntData.email) {
            validationErrors.email = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(cntData.email)) {
            validationErrors.email = "Email address is invalid";
        }

        if (!phone) {
            validationErrors.phone = "Phone is required";
        }
        if (!account_id) {
            validationErrors.account_id = "Account number is required";
        } else if (account_id?.length != 16) {
            validationErrors.account_id = "Account number should be 16 digits";
        }

        if (!routing_number) {
            validationErrors.routing_number = "Routing number is required";
        } else if (routing_number?.length != 9) {
            validationErrors.routing_number = "Routing number should be 9 digits";
        }
        Object.keys(validationErrors).forEach(key => {
            if (validationErrors[key] === '') {
                delete validationErrors[key];
            }
        });
        return validationErrors;
    };


    const handleSubmit = (e) => {
        e.preventDefault();
        const validationErrors = validate();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }
        Object.keys(errors).forEach(key => {
            if (errors[key] === '') {
                delete errors[key];
            }
        });
        if (Object.keys(errors).length > 0) {
            setErrors(errors);
            return;
        } else {
            let updatedCntData = { ...cntData, phone: phone?.slice(2), account_id, routing_number };
            navigate(`/contact-address/${params?.acc_id}`, { state: { data: updatedCntData, uuid: location?.state?.uuid } });
        }
    };

    return (
        <>
            {/* <MainHeader /> */}
            <div className="s-layout"> 
                <SideDefault />
                <main className="s-layout__content">
                    <div className="main-page">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card login-part">
                                    <div className="authincation-content">
                                        <div className="row no-gutters">
                                            <div className="col-xl-12">
                                                <div className="auth-form">
                                                    <h4 className="mb-4">Add Contacts</h4>
                                                    <form className="mt-4" onSubmit={handleSubmit}>
                                                        <div className="form-group uplabel">
                                                            <input
                                                                onChange={(e) => handleChange("first_name", e.target.value)}
                                                                name='first_name'
                                                                type="text"
                                                                id="form1"
                                                                className="form-control"
                                                                placeholder="Placeholder"
                                                                value={cntData?.first_name}
                                                            />
                                                            <label for="form1" className="mb-1 form-label">First Name or Business Name</label>
                                                            {errors.first_name && <small className="text-danger error">{errors.first_name}</small>}
                                                        </div>
                                                        <div className="form-group uplabel">
                                                            <input
                                                                onChange={(e) => handleChange("last_name", e.target.value)}
                                                                name='last_name'
                                                                type="text"
                                                                id="form2"
                                                                className="form-control"
                                                                placeholder="Placeholder"
                                                                value={cntData?.last_name}
                                                            />
                                                            <label for="form2" className="mb-1 form-label">Last Name</label>
                                                            {errors.last_name && <small className="text-danger error">{errors.last_name}</small>}
                                                        </div>
                                                        <div className="form-group uplabel">

                                                            <PhoneInput
                                                                country="US"
                                                                maxLength={14}
                                                                id="form3"
                                                                className="form-control"
                                                                placeholder="Placeholder"
                                                                value={phone}
                                                                onChange={(e) => { handlePhoneChange(e) }} />
                                                            <label for="form3" className="mb-1 form-label">Mobile number</label>
                                                            {errors.phone && <small className="text-danger error">{errors.phone}</small>}
                                                        </div>
                                                        <div className="form-group uplabel">
                                                            <input
                                                                name='email'
                                                                type="text"
                                                                id="form4"
                                                                className="form-control"
                                                                placeholder="Placeholder"
                                                                value={cntData?.email}
                                                                onChange={(e) => handleChange('email', e.target.value)}
                                                            />
                                                            <label for="form4" className="mb-1 form-label">Email</label>
                                                            {errors.email && <small className="text-danger error">{errors.email}</small>}
                                                        </div>
                                                        <div className="form-group uplabel">
                                                            <input
                                                                type="text"
                                                                id="form5"
                                                                className="form-control"
                                                                name='account_id'
                                                                placeholder="Placeholder"
                                                                maxLength={16}
                                                                value={account_id}
                                                                onChange={(e) => handleAccountChange(e)}
                                                            />
                                                            <label for="form5" className="mb-1 form-label">Account Number</label>
                                                            {errors.account_id && <small className="text-danger error">{errors.account_id}</small>}
                                                        </div>
                                                        <div className="form-group uplabel">
                                                            <input
                                                                name='routing_number'
                                                                type="text"
                                                                id="form6"
                                                                className="form-control"
                                                                placeholder="Placeholder"
                                                                maxLength={9}
                                                                value={routing_number}
                                                                onChange={(e) => handleRoutingChange(e.target.value)}
                                                            />
                                                            <label for="form6" className="mb-1 form-label">Routing Number</label>
                                                            {errors.routing_number && <small className="text-danger error">{errors.routing_number}</small>}
                                                        </div>
                                                        <div className="create-account mt-5">
                                                            <button>Create</button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>
    );
};

export default AddContact;
