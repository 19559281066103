import { AxiosError } from "axios";
import { Failure, UnAuthorizedException } from "./Errors";

const Helper = {
  isObject: (param) => {
    return typeof param === "object" && param !== null && !Array.isArray(param);
  },

  parseError: (error) => {
    const parseHttpError = (statusCode) => {
      switch (true) {
        case statusCode >= 400 && statusCode < 500:
          if (!error.response || !error.response.data) {
            console.error("ParseError: AxiosError Don't have response data");
            return new Failure("Unknown error", statusCode);
          }

          console.error("ParseError: AxiosError has Response Data");
          if (statusCode === 401) {
            return new UnAuthorizedException();
          }

          if (Helper.isObject(error.response.data)) {
            console.error("ParseError: AxiosError Response Data is An Object");
            // TODO: // parse error body
            // Currently i assumed it is like: { error : "<Some Error Message>"}
            return new Failure(
              error.response.data.error ?? "Unknown error",
              statusCode
            );
          }

          if (typeof error.response.data === "string") {
            console.error(
              "ParseError: AxiosError Response Data is String Type"
            );
            return new Failure("Something went wrong!", statusCode);
          }

          console.error(
            "ParseError: AxiosError Response Data Unknown type: ",
            typeof error.response.data
          );
          return new Failure("Unknown error", statusCode);

        case statusCode >= 500:
          return new Failure("Internal Server Error", statusCode);
        default:
          return new Failure("Unknown error", statusCode);
      }
    };

    if (!error) {
      console.error("ParseError: Error is undefined");
      return new Failure("Unknown error", 400);
    }

    if (error instanceof AxiosError) {
      const statusCode = error.status;
      console.error("ParseError: AxiosError with StatusCode: ", statusCode);
      return parseHttpError(statusCode);
    }

    console.error("ParseError: Not AxiosError: ", error);
    return new Failure(error.message ?? "Unknown error", 400);
  },
};

export default Helper;
