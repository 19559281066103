import React from "react";

import endPoints from "../constants";
import { useNavigate } from "react-router-dom";
import useFetch from "../hooks/useFetch";
import { Bars } from "react-loader-spinner";
import Sidebar from "../components/Sidebar";
import SideDefault from "../components/SideDefault";

const FetchStaffCards = () => {

    const navigate = useNavigate();
    const { data: cards, dataLoading: cardsLoading } = useFetch(
        `${endPoints.FETCH_STAFF_CARDS}`
    );
    // console.log("location", location?.state);

    const handleNavigate = (item) => {
        navigate(
            `/${item?.attributes?.name
                ?.trim()
                ?.replaceAll(" ", "-")
                ?.toLowerCase()}/accounts/${item?.attributes?.name
                    ?.trim()
                    ?.replaceAll(" ", "-")
                    ?.toLowerCase()}/cards/card-section/${item?.attributes?.uuid}`
        );
    };
    return (
        <>
            {!cardsLoading && (
                <div className="s-layout">
                    <SideDefault />
                    <main className="s-layout__content">
                        <div className="main-page">
                            <div className="row ">
                                <div className="col-xl-12">
                                    <div className="card plan-list">
                                        <div className="card-header d-sm-flex flex-wrap d-block pb-0 border-0">
                                            <div className="me-auto pe-3 mb-3">
                                                <h4 className="text-black fs-26 mb-0">Cards</h4>
                                            </div>
                                        </div>
                                        <div className="card-body  pt-2">
                                            <div className="row">
                                                {cards?.data?.length > 0 &&
                                                    cards?.data?.map((item, id) => {
                                                        // console.log()
                                                        return (
                                                            <div
                                                                className="col-md-4 col-sm-6 cursor-pointer"
                                                                key={item?.id}
                                                                onClick={() => handleNavigate(item)}
                                                            >
                                                                <div className="flex-wrap align-items-center card-box">
                                                                    <div className="col-xl-12 align-items-center">
                                                                        <a
                                                                            className="card-icons"
                                                                            data-bs-toggle="modal"
                                                                            data-bs-target="#exampleModalCenter"
                                                                        >
                                                                            <svg
                                                                                width={30}
                                                                                height={24}
                                                                                viewBox="0 0 30 24"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                                                            >
                                                                                <rect
                                                                                    width={30}
                                                                                    height="23.1579"
                                                                                    fill="url(#pattern0_293_274)"
                                                                                />
                                                                                <defs>
                                                                                    <pattern
                                                                                        id="pattern0_293_274"
                                                                                        patternContentUnits="objectBoundingBox"
                                                                                        width={1}
                                                                                        height={1}
                                                                                    >
                                                                                        <use
                                                                                            xlinkHref="#image0_293_274"
                                                                                            transform="matrix(0.0215539 0 0 0.0279221 0.0150376 0.0227273)"
                                                                                        />
                                                                                    </pattern>
                                                                                    <image
                                                                                        id="image0_293_274"
                                                                                        width={45}
                                                                                        height={35}
                                                                                        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC0AAAAjCAYAAAAAEIPqAAABmUlEQVR4Ae2YS26DMBCGZxzWXKC5AL1Auus625CH1F6gK3qP7pJDRMrDqy5zgbLqrr1AewGkLMN0DBUFBxmKImQiPonHODPRL3ts7EH4hRaLOzidAn71+HLBLkIQQuJ2K5WB6kbzeQBx/Ay2g7jC3W6JLNhnwS/QFeL40eGbrzVHfH2CLSDeANEws4UIHH6Mcg5f3P33YBk0nb7D3zzzRPFX+gY7yY+8K6CDOAWL84dmMxtXEZXXmVEUnSZ8AJbTyfToRbdFL7otrmCd5i0g7vcPYBn8GV9DbrvRp0db9KLbohfdFlexTp9B47ELrnurt+Nm81bHrww99r8YRdNkMuSD5CvXQ87qIHxYSI7zVX6l/5uLbYI5PQYDdVIvF0IU1PKrim2AWXQcm4bxo6afObYBxvRAKUMeyid+LeYqUQTHo6z0K0OLbULlROTcO/DjcCm/S9DZdTrK2R7YiCqN5U19r6pKY5ZVmvTSc+iwwCULXWdNae1jCLZCJIWa+YnwLkC0Yr0SM9v3fe5x9ZEYgV2kpWfu2KSDmR9nyJRallt0QAAAAABJRU5ErkJggg=="
                                                                                    />
                                                                                </defs>
                                                                            </svg>
                                                                        </a>
                                                                        <div className="list-icons text-capitalize">
                                                                            {item?.attributes?.card_holder_name[0]}
                                                                        </div>
                                                                        <div className="info mb-3 mt-3">
                                                                            <h4 className="fs-20 mb-0 text-black">
                                                                                {item?.attributes?.card_holder_name}
                                                                            </h4>
                                                                            <span>
                                                                                xxxx-{item?.attributes?.lastFour}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                {cards?.data?.length == 0 && (
                                                    <div className="">No Cards </div>
                                                )}

                                                {/* <div className="col-md-12">
                                                    <nav aria-label="..." className="d-flex mt-3 mb-3">
                                                        <ul className="pagination pagination-circle m-auto">
                                                            <li className="page-item">
                                                                <a className="page-link">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={15}
                                                                        height={15}
                                                                        viewBox="0 0 16 16"
                                                                    >
                                                                        <path
                                                                            fill="currentColor"
                                                                            d="M10.843 13.069L6.232 8.384a.546.546 0 0 1 0-.768l4.61-4.685a.552.552 0 0 0 0-.771a.53.53 0 0 0-.759 0l-4.61 4.684a1.65 1.65 0 0 0 0 2.312l4.61 4.684a.53.53 0 0 0 .76 0a.552.552 0 0 0 0-.771"
                                                                        />
                                                                    </svg>{" "}
                                                                    Prev
                                                                </a>
                                                            </li>
                                                            <li className="page-item">
                                                                <a className="page-link" href="#">
                                                                    1
                                                                </a>
                                                            </li>
                                                            <li className="page-item active" aria-current="page">
                                                                <a className="page-link" href="#">
                                                                    2 <span className="visually-hidden">(current)</span>
                                                                </a>
                                                            </li>
                                                            <li className="page-item">
                                                                <a className="page-link" href="#">
                                                                    3
                                                                </a>
                                                            </li>
                                                            <li className="page-item">
                                                                <a className="page-link" href="#">
                                                                    Next{" "}
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={17}
                                                                        height={17}
                                                                        viewBox="0 0 7 16"
                                                                    >
                                                                        <path
                                                                            fill="currentColor"
                                                                            d="M1.5 13a.47.47 0 0 1-.35-.15c-.2-.2-.2-.51 0-.71L5.3 7.99L1.15 3.85c-.2-.2-.2-.51 0-.71c.2-.2.51-.2.71 0l4.49 4.51c.2.2.2.51 0 .71l-4.5 4.49c-.1.1-.23.15-.35.15Z"
                                                                        />
                                                                    </svg>
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </nav>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            )}
            <Bars
                height="80"
                width="80"
                color="#39AFFF"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass="bars"
                visible={cardsLoading}
            />
        </>
    );
};

export default FetchStaffCards;
