
export const hasValue = (value: any): boolean => {
    return value !== null && value !== undefined && value !== ''
}


interface TransactionParams {
    id: number,
    amount: string,
    transfer_type: string | null,
    ach_account_number: string | null,
    ach_bank_name: string | null,
    account_id: number | null,
    created_at: string,
    account_to_id: number | null,
    marqeta_transaction_token: string | null,
    send_from: string | null,
    card_id: number | null,
    description: string | null,
    finxact_order_id: string | null,
    acceptor_name: string | null,
    lastFour: string | null,
    status: string | null,
    acceptor_mid: string | null,
    acceptor_mcc: string | null,
    title: string | null,
    txn_type: string | null,
    txn_category: string | null,
    legal_name: string | null,
    finxact_party_account_num: string | null,
}

class TransactionsHelper {

    private static isCardTransaction = (txnType: string | null): boolean => {
        return txnType?.toLowerCase() === 'card'
    }

    private static isWireTransfer = (txnType: string | null): boolean => {
        return txnType?.toLowerCase() === 'wire'
    }
    private static isIncomingWireTransfer = (txnType: string | null): boolean => {
        return txnType?.toLowerCase() == 'incoming wire'
    }

    private static isIntraBankTransaction = (txnType: string | null): boolean => {
        return txnType?.toLowerCase() === 'core'
    }

    private static isACHTransaction = (txnType: string | null | undefined): boolean => {
        return txnType?.toLowerCase() === "ach"
    }

    private static isACHOutgoingTransaction = (txnType: string | null | undefined): boolean => {
        return txnType?.toLowerCase() === "achoutgoing"
    }

    private static isACHIncomingTransaction = (txnType: string | null | undefined): boolean => {
        return txnType?.toLowerCase() === "achincoming"
    }

    private static isFeeTransaction = (txnType: string | null | undefined): boolean => {
        return txnType?.toLowerCase() === "fee"
    }
    private static isCoreTransfer = (txnType: string | null | undefined): boolean => {
        return txnType == "Book Transfer"
    }
    private static isAchInTransfer = (txnType: string | null | undefined): boolean => {
        return txnType == "INCOMING ACH CREDIT"
    }

    static getLabel(transaction: TransactionParams, accountId: number): string {
        // this is use for getting the label
        let name = transaction.title?.trim() ?? 'No Name'
        if (TransactionsHelper.isCardTransaction(transaction.txn_type)) {
            name = transaction.acceptor_name?.trim() ?? 'No Name'
        } else if (transaction.account_to_id == accountId && TransactionsHelper.isIntraBankTransaction(transaction.txn_type)) {
            name = `${transaction.legal_name?.trim()} ${transaction?.finxact_party_account_num?.slice(-4)
                }` ?? 'No Name'
        } else if (transaction.account_id !== accountId) {
            name = transaction.send_from?.trim() ?? 'No Name'
        }
        return name
    }

    static getTransactionId(transaction: TransactionParams): string {
        // this is use for getting the transaction id
        let id = transaction?.finxact_order_id ?? transaction?.desc ?? '--'
        if (TransactionsHelper.isCardTransaction(transaction.txn_type)) {
            id = transaction?.marqeta_transaction_token ?? '--'
        }
        return id
    }

    private static getIntraBankStatus(transaction: TransactionParams, accountId: number): string {
        // this is use for getting the intrabank status
        return transaction.account_id === accountId ? "Transfer Out" : "Transfer In"
    }



    private static getCardStatus(transaction: TransactionParams): string {
        // this is use for getting the status of card that its is purchase or refund
        let status = "Purchase"
        if (transaction.txn_category?.toLowerCase() === "refund") {
            status = "Return"
        }
        return status
    }

    private static getACHStatus(transaction: TransactionParams): string {
        // this is use for getting the type of transaction is "Withdrawal" or "Deposit"
        return TransactionsHelper.isACHOutgoingTransaction(transaction.txn_type) ? "Withdrawal" : "Deposit"
    }
    private static getFeeStatus(transaction: TransactionParams): string {
        // this is use for getting the type of transaction is "Withdrawal" or "Deposit"
        return TransactionsHelper.isFeeTransaction(transaction.txn_type) ? "Withdrawal" : "Deposit"
    }

    static getCreditDebitStatus(transaction: TransactionParams, accountId: number): string {
        // this is use for getting the card debit transaction
        if (!hasValue(transaction.txn_type)) return "--"

        if (TransactionsHelper.isIntraBankTransaction(transaction.txn_type)) {
            return TransactionsHelper.getIntraBankStatus(transaction, accountId)
        } else if (TransactionsHelper.isCardTransaction(transaction.txn_type)) {
            return TransactionsHelper.getCardStatus(transaction)
        } else if (TransactionsHelper.isFeeTransaction(transaction.txn_type)) {
            return TransactionsHelper.getFeeStatus(transaction)
        } else {
            return TransactionsHelper.getACHStatus(transaction)
        }
    }



    static getTransactionType(transaction: TransactionParams) {
        // this function is use for getting the transaction type
        // console.log("transaction.txn_type:::", transaction.txn_type);

        if (!hasValue(transaction.txn_type)) return "Unknown"

        if (TransactionsHelper.isACHOutgoingTransaction(transaction.txn_type)) {
            return "ACH OUT"
        }
        else if (TransactionsHelper.isWireTransfer(transaction.txn_type)) {
            return "Wire"
        }
        else if (TransactionsHelper.isAchInTransfer(transaction.txn_type)) {
            return "INCOMING ACH CREDIT"
        }
        else if (TransactionsHelper.isIncomingWireTransfer(transaction.txn_type)) {
            return "Incoming Wire"
        }
        else if (TransactionsHelper.isCoreTransfer(transaction.txn_type)) {
            return "Core"
        }
        else if (TransactionsHelper.isACHTransaction(transaction.txn_type))
            return "ACH"
        else if (TransactionsHelper.isACHIncomingTransaction(transaction.txn_type))
            return "ACH IN"
        else if (TransactionsHelper.isIntraBankTransaction(transaction.txn_type)) {
            return "Intrabank"
        }
        else if (TransactionsHelper.isACHOutgoingTransaction(transaction.txn_type)) {
            return "ACH OUT"
        }
        else if (TransactionsHelper.isFeeTransaction(transaction.txn_type)) {
            return "Fee"
        }

        else if (TransactionsHelper.isCardTransaction(transaction.txn_type)) {
            let status = 'Unknown'

            if (!hasValue(transaction?.txn_category)) return status;

            if (transaction.txn_category) {
                switch (transaction.txn_category) {
                    case "authorization.advice":
                    case "authorization":
                    case "authorization.clearing":
                    case "account.funding.authorization.clearing":
                    case "account.funding.authorization":
                    case "pindebit.atm.withdrawal":
                    case "authorization.atm.withdrawal":
                    case "authorization.clearing.atm.withdrawal":
                        status = 'Card Spend'
                        break;
                    case "refund":
                        status = "Refund"
                        break;
                    case "pindebit":
                        status = 'PIN Debit'
                        break;
                    default:
                        status = "Unknown"
                        break;
                }
            }
            if (transaction?.lastFour) {
                status = `${status} (${transaction?.lastFour})`
            }
            return status
        }
    }

    /* *
    * 1. if transaction is ACH Outgoing
    * 2. if transaction is IntraBank transaction and account id matched with transaction's account id
    * 3. if transaction is card transaction and it's should not be refunded
    */
    //    this function is use for checking the transaction is debit or not
    static isDebit(transaction: TransactionParams, accountId: number): boolean {
        return TransactionsHelper.isACHOutgoingTransaction(transaction.txn_type) ||
            (
                (TransactionsHelper.isIntraBankTransaction(transaction.txn_type) && transaction.account_id == accountId) ||
                (TransactionsHelper.isFeeTransaction(transaction.txn_type) && transaction.txn_category?.toLowerCase() !== "fee") ||
                (TransactionsHelper.isCardTransaction(transaction.txn_type) && transaction.txn_category?.toLowerCase() !== "refund") ||
                (TransactionsHelper.isWireTransfer(transaction.txn_type))
            )
    }

}

export default TransactionsHelper

























// interface TransactionParams {
//   id: number;
//   amount: string;
//   transfer_type: string | null;
//   ach_account_number: string | null;
//   ach_bank_name: string | null;
//   account_id: number | null;
//   created_at: string;
//   account_to_id: number | null;
//   marqeta_transaction_token: string | null;
//   send_from: string | null;
//   card_id: number | null;
//   description: string | null;
//   finxact_order_id: string | null;
//   acceptor_name: string | null;
//   lastFour: string | null;
//   status: string | null;
//   acceptor_mid: string | null;
//   acceptor_mcc: string | null;
//   title: string | null;
//   txn_type: string | null;
//   txn_category: string | null;
//   legal_name: string | null;
//   tp_account_number: string | null;
// }

// class TransactionsHelper {

//     private static isCardTransaction = (txnType: string | null): boolean => {
//         return txnType?.toLowerCase() === 'card'
//     }

//     private static isIntraBankTransaction = (txnType: string | null): boolean => {
//         return txnType?.toLowerCase() === 'core'
//     }
//     private static isACHTransaction = (txnType: string | null | undefined): boolean => {
//         return txnType?.toLowerCase() === "ach"
//     }

//     private static isACHOutgoingTransaction = (txnType: string | null | undefined): boolean => {
//         return txnType?.toLowerCase() === "achoutgoing"
//     }

//     private static isACHIncomingTransaction = (txnType: string | null | undefined): boolean => {
//         return txnType?.toLowerCase() === "achincoming"
//     }

//     private static isFeeTransaction = (txnType: string | null | undefined): boolean => {
//         return txnType?.toLowerCase() === "fee"
//     }

//     static getLabel(transaction: TransactionParams, accountId: number): string {
//         let name = transaction.title?.trim() ?? 'No Name'
//         if (TransactionsHelper.isCardTransaction(transaction.txn_type)) {
       
            
//             name = transaction.acceptor_name?.trim() ?? 'No Name'
//         } else if (transaction.account_to_id == accountId && TransactionsHelper.isIntraBankTransaction(transaction.txn_type)) {
            
//             const value = `${transaction.legal_name?.trim()} ${transaction?.tp_account_number?.slice(
//                 -4
//               )}`

//             name = !value || value.length == 0 ? "No Name" : value;
//         } else if (transaction.account_id !== accountId) {
           

//             name = transaction.send_from?.trim() ?? 'No Name'
//         }else{
            

//             name = transaction.title?.trim() ?? 'No Name'
//         }
//         return name
//     }

//     static getTransactionId(transaction: TransactionParams): string {
//         let id = transaction?.finxact_order_id ?? '--'
//         if (TransactionsHelper.isCardTransaction(transaction.txn_type)) {
//             id = transaction?.marqeta_transaction_token ?? '--'
//         }
//         return id
//     }

//     private static getIntraBankStatus(transaction: TransactionParams, accountId: number): string {
//         return transaction.account_id === accountId ? "Transfer Out" : "Transfer In"
//     }



//     private static getCardStatus(transaction: TransactionParams): string {
//         let status = "Purchase"
//         if (transaction.txn_category?.toLowerCase() === "refund") {
//             status = "Return"
//         }
//         return status
//     }

//     private static getACHStatus(transaction: TransactionParams): string {
//         return TransactionsHelper.isACHOutgoingTransaction(transaction.txn_type) ? "Withdrawal" : "Deposit"
//     }
//     private static getFeeStatus(transaction: TransactionParams): string {
//         return TransactionsHelper.isFeeTransaction(transaction.txn_type) ? "Withdrawal" : "Deposit"
//     }

//     static getCreditDebitStatus(transaction: TransactionParams, accountId: number): string {
//         if (!transaction.txn_type) return "--"

//         if (TransactionsHelper.isIntraBankTransaction(transaction.txn_type)) {
//             return TransactionsHelper.getIntraBankStatus(transaction, accountId)
//         } else if (TransactionsHelper.isCardTransaction(transaction.txn_type)) {
//             return TransactionsHelper.getCardStatus(transaction)
//         } else if (TransactionsHelper.isFeeTransaction(transaction.txn_type)) {
//             return TransactionsHelper.getFeeStatus(transaction)
//         } else {
//             return TransactionsHelper.getACHStatus(transaction)
//         }
//     }

//     static getTransactionType(transaction: TransactionParams) {

//         if (!transaction.txn_type) return "Unknown"

//         if (TransactionsHelper.isACHOutgoingTransaction(transaction.txn_type)) {
//             return "ACH OUT"
//         }
//         else if (TransactionsHelper.isACHTransaction(transaction.txn_type))
//             return "ACH"
//         else if (TransactionsHelper.isACHIncomingTransaction(transaction.txn_type))
//             return "ACH IN"
//         else if (TransactionsHelper.isIntraBankTransaction(transaction.txn_type)) {
//             return "Intrabank"
//         }
//         else if (TransactionsHelper.isACHOutgoingTransaction(transaction.txn_type)) {
//             return "ACH OUT"
//         }
//         else if (TransactionsHelper.isFeeTransaction(transaction.txn_type)) {
//             return "Fee"
//         }

//         else if (TransactionsHelper.isCardTransaction(transaction.txn_type)) {
//             let status = 'Unknown'

//             if (!transaction?.txn_category) return status;

//             if (transaction.txn_category) {
//                 switch (transaction.txn_category) {
//                     case "authorization.advice":
//                     case "authorization":
//                     case "authorization.clearing":
//                     case "account.funding.authorization.clearing":
//                     case "account.funding.authorization":
//                     case "pindebit.atm.withdrawal":
//                     case "authorization.atm.withdrawal":
//                     case "authorization.clearing.atm.withdrawal":
//                         status = 'Card Spend'
//                         break;
//                     case "refund":
//                         status = "Refund"
//                         break;
//                     case "pindebit":
//                         status = 'PIN Debit'
//                         break;
//                     default:
//                         status = "Unknown"
//                         break;
//                 }
//             }
//             if (transaction?.lastFour) {
//                 status = `${status} (${transaction?.lastFour})`
//             }
//             return status
//         }
//     }

//     /* *
//     * 1. if transaction is ACH Outgoing
//     * 2. if transaction is IntraBank transaction and account id matched with transaction's account id
//     * 3. if transaction is card transaction and it's should not be refunded
//     */
//     static isDebit(transaction: TransactionParams, accountId: number): boolean {
//         return TransactionsHelper.isACHOutgoingTransaction(transaction.txn_type) ||
//             (
//                 (TransactionsHelper.isIntraBankTransaction(transaction.txn_type) && transaction.account_id == accountId) ||
//                 (TransactionsHelper.isFeeTransaction(transaction.txn_type) && transaction.txn_category?.toLowerCase() !== "fee") ||
//                 (TransactionsHelper.isCardTransaction(transaction.txn_type) && transaction.txn_category?.toLowerCase() !== "refund")
//             )
//     }

// }

// export default TransactionsHelper